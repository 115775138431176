
  import { Component, Vue } from 'vue-property-decorator'
  @Component({
    name:"index5Layout",
    components:{
      djhNav:require('~/components/comm/djhNav/index.vue').default,
      djhNav8:require('~/components/comm/djhNav/djhNav8.vue').default,
      djhFooter:require('~/components/comm/djhFooter/index.vue').default,
      footerService4:require('~/components/comm/djhFooter/footerService4.vue').default,
      floatMenu9:require('~/components/comm/floatMenu/index9.vue').default,
      floatLeft1:require("~/components/comm/floatLeft/index.vue").default,
      kefuVerify: require("~/components/comm/kefuVerify/index.vue").default,
    },
    // 修改网页头
    head() {
      return {
        script: [
          { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sllSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sllSl:'' },//360收录
          { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).baiduSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).baiduSl:'' },//百度收录
          // { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sougouSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).sougouSl:'' },//搜狗收录
          // { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).shenmaSl)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).shenmaSl:'' },//神马收录
          { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).baiduTj)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).baiduTj:'' },//百度统计
          { src: (this.$store.state.web.distributorsObject.distBaseConf.statTrafScript && JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).fiveoneTj)?JSON.parse(this.$store.state.web.distributorsObject.distBaseConf.statTrafScript).fiveoneTj:'',id:"LA_COLLECT" },//百度统计
        ],
        title: this.$store.state.web.distributorsObject.distBaseConf.websiteName,
        meta: [
          {
            hid: 'description',
            name: 'description',//网站描述
            content: this.$store.state.web.distributorsObject.distBaseConf.websiteDesc//网站描述
          },
          {
            hid: 'keywords',
            name: 'keywords',//网站关键字
            content: this.$store.state.web.distributorsObject.distBaseConf.webKeyWordStr//网站关键字
          },
        ],
        link: [
          { rel: 'icon', type: 'image/x-icon', href: 'https://oss1.hk1686.com/'+this.$store.state.web.distributorsObject.pictArea.webIcon }
        ]
      }
    }
  })
  export default class index5Layout extends Vue {
    private background:String='#E7E7E7'
    private color:String='#717171'
    private marginTop:String='0'
    mounted(){
      let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
      body[0].setAttribute('style', 'background-color:#F2F3F5;')
    }
  }
