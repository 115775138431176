import createPersistedState from 'vuex-persistedstate'
export default ({store}) => {
  window.onNuxtReady(() => {
    createPersistedState({
      // key: Cookies.get(key),
      // key:"nuxt",
      paths: [
        // 'buy.orderInfo',(示例)
        // 'center.config',(示例)
        // 'comm.userInfo',(示例)
        // 'web.distributorsObject',
        'web.footerImgArray',
        'web.topMenuArray',
        'web.gamefilterArray',
        'web.hotGamesArray',
        'web.gameListArray',
        'web.fastGoldOrder',
        'web.editGoodsObject',
        'web.secondTitleArray',
      ],
        storage :window.sessionStorage
    }
    )(store)
  })
}
