import axios from "axios";
import configRequest from "../plugins/axios/config";

//实例
const Axios = axios.create({
  timeout:5000,
  baseURL:configRequest.customerRequestUrl
})

Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'


//请求拦截器
Axios.interceptors.request.use(
  config=>{
    return config
  },
  error=>Promise.reject(error)

)

//响应拦截器
Axios.interceptors.response.use(
  //请求成功
  res=>res.status===200?Promise.resolve(res.data):Promise.reject(res),
  //请求失败
  error=>{
    if(error.response.status){
      error.response.status===404?console.log("请求不存在！！"):console.log("其他")
    }
    return Promise.reject(error)
  }
)


export default Axios
