import Vue from 'vue'
import { decode, parsePath, withoutBase, withoutTrailingSlash, normalizeURL } from 'ufo'

import { getMatchedComponentsInstances, getChildrenComponentInstancesUsingFetch, promisify, globalHandleError, urlJoin, sanitizeComponent } from './utils'
import NuxtError from './components/nuxt-error.vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '..\\assets\\page-transletion.css'

import '..\\node_modules\\viewerjs\\dist\\viewer.css'

import '..\\node_modules\\swiper\\dist\\css\\swiper.css'

import _f3c28b5a from '..\\layouts\\blackLayout.vue'
import _7022dcbc from '..\\layouts\\centerCodeLayout.vue'
import _35e3a2d0 from '..\\layouts\\centerCodeLayout2.vue'
import _8d9caa56 from '..\\layouts\\centerLayout.vue'
import _f27946d6 from '..\\layouts\\defaultLayout.vue'
import _64ac6be0 from '..\\layouts\\index2Layout.vue'
import _ccdab1be from '..\\layouts\\index3Layout.vue'
import _630e3b3c from '..\\layouts\\index4Layout.vue'
import _035f1da3 from '..\\layouts\\index5Layout.vue'
import _384558e4 from '..\\layouts\\index6Layout.vue'
import _6d2b9425 from '..\\layouts\\index7Layout.vue'
import _8599ae34 from '..\\layouts\\indexLayout.vue'
import _8864358c from '..\\layouts\\loginModal.vue'
import _27dc6088 from '..\\layouts\\singleLayout.vue'
import _ef5d718c from '..\\layouts\\storeManageLayout.vue'
import _6f6c098b from './layouts/default.vue'

const layouts = { "_blackLayout": sanitizeComponent(_f3c28b5a),"_centerCodeLayout": sanitizeComponent(_7022dcbc),"_centerCodeLayout2": sanitizeComponent(_35e3a2d0),"_centerLayout": sanitizeComponent(_8d9caa56),"_defaultLayout": sanitizeComponent(_f27946d6),"_index2Layout": sanitizeComponent(_64ac6be0),"_index3Layout": sanitizeComponent(_ccdab1be),"_index4Layout": sanitizeComponent(_630e3b3c),"_index5Layout": sanitizeComponent(_035f1da3),"_index6Layout": sanitizeComponent(_384558e4),"_index7Layout": sanitizeComponent(_6d2b9425),"_indexLayout": sanitizeComponent(_8599ae34),"_loginModal": sanitizeComponent(_8864358c),"_singleLayout": sanitizeComponent(_27dc6088),"_storeManageLayout": sanitizeComponent(_ef5d718c),"_default": sanitizeComponent(_6f6c098b) }

export default {
  render (h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })

    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [layoutEl])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter (el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [templateEl])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,

      transitionEl
    ])
  },

  data: () => ({
    isOnline: true,

    layout: null,
    layoutName: '',

    nbFetching: 0
    }),

  beforeCreate () {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created () {
    // Add this.$nuxt in child instances
    this.$root.$options.$nuxt = this

    if (process.client) {
      // add to window so we can listen when ready
      window.$nuxt = this

      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
    // Add $nuxt.context
    this.context = this.$options.context
  },

  async mounted () {
    this.$loading = this.$refs.loading
  },

  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline () {
      return !this.isOnline
    },

    isFetching () {
      return this.nbFetching > 0
    },
  },

  methods: {
    refreshOnlineStatus () {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    async refresh () {
      const pages = getMatchedComponentsInstances(this.$route)

      if (!pages.length) {
        return
      }
      this.$loading.start()

      const promises = pages.map(async (page) => {
        let p = []

        // Old fetch
        if (page.$options.fetch && page.$options.fetch.length) {
          p.push(promisify(page.$options.fetch, this.context))
        }

        if (page.$options.asyncData) {
          p.push(
            promisify(page.$options.asyncData, this.context)
              .then((newData) => {
                for (const key in newData) {
                  Vue.set(page.$data, key, newData[key])
                }
              })
          )
        }

        // Wait for asyncData & old fetch to finish
        await Promise.all(p)
        // Cleanup refs
        p = []

        if (page.$fetch) {
          p.push(page.$fetch())
        }
        // Get all component instance to call $fetch
        for (const component of getChildrenComponentInstancesUsingFetch(page.$vnode.componentInstance)) {
          p.push(component.$fetch())
        }

        return Promise.all(p)
      })
      try {
        await Promise.all(promises)
      } catch (error) {
        this.$loading.fail(error)
        globalHandleError(error)
        this.error(error)
      }
      this.$loading.finish()
    },
    errorChanged () {
      if (this.nuxt.err) {
        if (this.$loading) {
          if (this.$loading.fail) {
            this.$loading.fail(this.nuxt.err)
          }
          if (this.$loading.finish) {
            this.$loading.finish()
          }
        }

        let errorLayout = (NuxtError.options || NuxtError).layout;

        if (typeof errorLayout === 'function') {
          errorLayout = errorLayout(this.context)
        }

        this.setLayout(errorLayout)
      }
    },

    setLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout (layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    },
  },

  components: {
    NuxtLoading
  }
}
