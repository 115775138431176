import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a24ccb58 = () => interopDefault(import('..\\pages\\allgame.vue' /* webpackChunkName: "pages/allgame" */))
const _0ed1506f = () => interopDefault(import('..\\pages\\auctionPage\\index.vue' /* webpackChunkName: "pages/auctionPage/index" */))
const _7e8931fc = () => interopDefault(import('..\\pages\\black\\index.vue' /* webpackChunkName: "pages/black/index" */))
const _e26c8644 = () => interopDefault(import('..\\pages\\center\\index.vue' /* webpackChunkName: "pages/center/index" */))
const _18ee2281 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _04b6599e = () => interopDefault(import('..\\pages\\Conversations\\index.vue' /* webpackChunkName: "pages/Conversations/index" */))
const _51e8dd0a = () => interopDefault(import('..\\pages\\dealshow\\index.vue' /* webpackChunkName: "pages/dealshow/index" */))
const _4d9775f7 = () => interopDefault(import('..\\pages\\forgetPassword.vue' /* webpackChunkName: "pages/forgetPassword" */))
const _e388e9e6 = () => interopDefault(import('..\\pages\\gj-wzry\\index.vue' /* webpackChunkName: "pages/gj-wzry/index" */))
const _689fdc3d = () => interopDefault(import('..\\pages\\jump.vue' /* webpackChunkName: "pages/jump" */))
const _54fc0757 = () => interopDefault(import('..\\pages\\kfyz\\index.vue' /* webpackChunkName: "pages/kfyz/index" */))
const _0c7df85d = () => interopDefault(import('..\\pages\\log\\index.vue' /* webpackChunkName: "pages/log/index" */))
const _1eab57ea = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _6dcee9e0 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _78867e9c = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _337d5aa1 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _7f92a50b = () => interopDefault(import('..\\pages\\wares\\index.vue' /* webpackChunkName: "pages/wares/index" */))
const _1d8f42b8 = () => interopDefault(import('..\\pages\\Bargbuy\\Babuy.vue' /* webpackChunkName: "pages/Bargbuy/Babuy" */))
const _33870578 = () => interopDefault(import('..\\pages\\Bargbuy\\Partialpayment.vue' /* webpackChunkName: "pages/Bargbuy/Partialpayment" */))
const _4d4ba1ed = () => interopDefault(import('..\\pages\\black\\blackList.vue' /* webpackChunkName: "pages/black/blackList" */))
const _720b9ad5 = () => interopDefault(import('..\\pages\\black\\center.vue' /* webpackChunkName: "pages/black/center" */))
const _f8efc990 = () => interopDefault(import('..\\pages\\black\\releaseBlack.vue' /* webpackChunkName: "pages/black/releaseBlack" */))
const _5170a69a = () => interopDefault(import('..\\pages\\black\\zhgs.vue' /* webpackChunkName: "pages/black/zhgs" */))
const _9b977784 = () => interopDefault(import('..\\pages\\center\\buyers\\index.vue' /* webpackChunkName: "pages/center/buyers/index" */))
const _7e9fc3cc = () => interopDefault(import('..\\pages\\center\\sell\\index.vue' /* webpackChunkName: "pages/center/sell/index" */))
const _2bd9f597 = () => interopDefault(import('..\\pages\\dealshow\\selltime.vue' /* webpackChunkName: "pages/dealshow/selltime" */))
const _332085b7 = () => interopDefault(import('..\\pages\\fastGold\\fastGoldOrder.vue' /* webpackChunkName: "pages/fastGold/fastGoldOrder" */))
const _3b23bdd3 = () => interopDefault(import('..\\pages\\gj-wzry\\hpjyEvaluator.vue' /* webpackChunkName: "pages/gj-wzry/hpjyEvaluator" */))
const _1907fc73 = () => interopDefault(import('..\\pages\\specilPage\\createAccountInfo.vue' /* webpackChunkName: "pages/specilPage/createAccountInfo" */))
const _8bc5b9e6 = () => interopDefault(import('..\\pages\\specilPage\\downloadApp.vue' /* webpackChunkName: "pages/specilPage/downloadApp" */))
const _87e27ca8 = () => interopDefault(import('..\\pages\\specilPage\\hpjyEvaluator.vue' /* webpackChunkName: "pages/specilPage/hpjyEvaluator" */))
const _495da3a7 = () => interopDefault(import('..\\pages\\center\\activity\\play.vue' /* webpackChunkName: "pages/center/activity/play" */))
const _377f0cbd = () => interopDefault(import('..\\pages\\center\\assets\\assetsFinancing.vue' /* webpackChunkName: "pages/center/assets/assetsFinancing" */))
const _adcaefe0 = () => interopDefault(import('..\\pages\\center\\buyers\\BuyBargain.vue' /* webpackChunkName: "pages/center/buyers/BuyBargain" */))
const _45abdc76 = () => interopDefault(import('..\\pages\\center\\buyers\\buyBargainGoods.vue' /* webpackChunkName: "pages/center/buyers/buyBargainGoods" */))
const _e8e376ee = () => interopDefault(import('..\\pages\\center\\buyers\\buyComplaint.vue' /* webpackChunkName: "pages/center/buyers/buyComplaint" */))
const _0022cfee = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGold.vue' /* webpackChunkName: "pages/center/buyers/buyFastGold" */))
const _4bd34942 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGoldDetail.vue' /* webpackChunkName: "pages/center/buyers/buyFastGoldDetail" */))
const _437fc22a = () => interopDefault(import('..\\pages\\center\\buyers\\buyGoodsOrder.vue' /* webpackChunkName: "pages/center/buyers/buyGoodsOrder" */))
const _7ed2cfe6 = () => interopDefault(import('..\\pages\\center\\buyers\\buyGuarantee.vue' /* webpackChunkName: "pages/center/buyers/buyGuarantee" */))
const _b3ec588a = () => interopDefault(import('..\\pages\\center\\buyers\\buyLikedGoods.vue' /* webpackChunkName: "pages/center/buyers/buyLikedGoods" */))
const _6bde9d9d = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderDetail.vue' /* webpackChunkName: "pages/center/buyers/buyOrderDetail" */))
const _66b49f0c = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/buyOrderInfo" */))
const _61efc01e = () => interopDefault(import('..\\pages\\center\\buyers\\buySeckillOrder.vue' /* webpackChunkName: "pages/center/buyers/buySeckillOrder" */))
const _2352257d = () => interopDefault(import('..\\pages\\center\\buyers\\lotteryRecords.vue' /* webpackChunkName: "pages/center/buyers/lotteryRecords" */))
const _3124056e = () => interopDefault(import('..\\pages\\center\\buyers\\sellerOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/sellerOrderInfo" */))
const _a09859aa = () => interopDefault(import('..\\pages\\center\\extend\\extendMyExtension.vue' /* webpackChunkName: "pages/center/extend/extendMyExtension" */))
const _6c23deec = () => interopDefault(import('..\\pages\\center\\extend\\qrCode.vue' /* webpackChunkName: "pages/center/extend/qrCode" */))
const _561b833c = () => interopDefault(import('..\\pages\\center\\extend\\sellgooSn.vue' /* webpackChunkName: "pages/center/extend/sellgooSn" */))
const _409b8016 = () => interopDefault(import('..\\pages\\center\\feedback\\complaintcustomerservice.vue' /* webpackChunkName: "pages/center/feedback/complaintcustomerservice" */))
const _56955e9d = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackandsuggestion.vue' /* webpackChunkName: "pages/center/feedback/feedbackandsuggestion" */))
const _43302373 = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackrecording.vue' /* webpackChunkName: "pages/center/feedback/feedbackrecording" */))
const _77699018 = () => interopDefault(import('..\\pages\\center\\log\\history.vue' /* webpackChunkName: "pages/center/log/history" */))
const _f66c9a7e = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\index.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/index" */))
const _458139f8 = () => interopDefault(import('..\\pages\\center\\myself\\buyIdentity.vue' /* webpackChunkName: "pages/center/myself/buyIdentity" */))
const _240d4164 = () => interopDefault(import('..\\pages\\center\\myself\\cashWithdrawal.vue' /* webpackChunkName: "pages/center/myself/cashWithdrawal" */))
const _555f6e60 = () => interopDefault(import('..\\pages\\center\\myself\\changeEmail.vue' /* webpackChunkName: "pages/center/myself/changeEmail" */))
const _d42384b2 = () => interopDefault(import('..\\pages\\center\\myself\\changePayPassword.vue' /* webpackChunkName: "pages/center/myself/changePayPassword" */))
const _ccb1b19c = () => interopDefault(import('..\\pages\\center\\myself\\changePhone.vue' /* webpackChunkName: "pages/center/myself/changePhone" */))
const _655ba45c = () => interopDefault(import('..\\pages\\center\\myself\\depositWithdraw.vue' /* webpackChunkName: "pages/center/myself/depositWithdraw" */))
const _096bd0f2 = () => interopDefault(import('..\\pages\\center\\myself\\identityAuthentication.vue' /* webpackChunkName: "pages/center/myself/identityAuthentication" */))
const _62fe47e2 = () => interopDefault(import('..\\pages\\center\\myself\\myselfInfo.vue' /* webpackChunkName: "pages/center/myself/myselfInfo" */))
const _15f0097a = () => interopDefault(import('..\\pages\\center\\myself\\recharge.vue' /* webpackChunkName: "pages/center/myself/recharge" */))
const _dd7bdb4c = () => interopDefault(import('..\\pages\\center\\myself\\thirdPartyBinding.vue' /* webpackChunkName: "pages/center/myself/thirdPartyBinding" */))
const _ed118c68 = () => interopDefault(import('..\\pages\\center\\sell\\sellBargainGoods.vue' /* webpackChunkName: "pages/center/sell/sellBargainGoods" */))
const _45727016 = () => interopDefault(import('..\\pages\\center\\sell\\sellOrder.vue' /* webpackChunkName: "pages/center/sell/sellOrder" */))
const _5b689c10 = () => interopDefault(import('..\\pages\\center\\sell\\sellOutGoods.vue' /* webpackChunkName: "pages/center/sell/sellOutGoods" */))
const _74240d2b = () => interopDefault(import('..\\pages\\center\\sell\\Sellreceive.vue' /* webpackChunkName: "pages/center/sell/Sellreceive" */))
const _44c23a4b = () => interopDefault(import('..\\pages\\center\\sell\\sellSuccess.vue' /* webpackChunkName: "pages/center/sell/sellSuccess" */))
const _225e8e46 = () => interopDefault(import('..\\pages\\center\\sell\\sellTab.vue' /* webpackChunkName: "pages/center/sell/sellTab" */))
const _7e88e90c = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePassword.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePassword" */))
const _36866e4d = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePhone.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePhone" */))
const _5503b4d3 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _478eb9ac = () => interopDefault(import('..\\pages\\black\\_sn.vue' /* webpackChunkName: "pages/black/_sn" */))
const _ba19a0ec = () => interopDefault(import('..\\pages\\detail\\_sn.vue' /* webpackChunkName: "pages/detail/_sn" */))
const _a08580a4 = () => interopDefault(import('..\\pages\\JDetail\\_sn.vue' /* webpackChunkName: "pages/JDetail/_sn" */))
const _6f999ada = () => interopDefault(import('..\\pages\\news\\_code.vue' /* webpackChunkName: "pages/news/_code" */))
const _797ce7eb = () => interopDefault(import('..\\pages\\goods\\_sn\\order.vue' /* webpackChunkName: "pages/goods/_sn/order" */))
const _22075cf6 = () => interopDefault(import('..\\pages\\goods\\_sn\\pay.vue' /* webpackChunkName: "pages/goods/_sn/pay" */))
const _bfe3de40 = () => interopDefault(import('..\\pages\\goods\\_sn\\success.vue' /* webpackChunkName: "pages/goods/_sn/success" */))
const _7478e3aa = () => interopDefault(import('..\\pages\\wares\\_sn\\order.vue' /* webpackChunkName: "pages/wares/_sn/order" */))
const _4590f076 = () => interopDefault(import('..\\pages\\wares\\_sn\\pay.vue' /* webpackChunkName: "pages/wares/_sn/pay" */))
const _1577b1c0 = () => interopDefault(import('..\\pages\\wares\\_sn\\success.vue' /* webpackChunkName: "pages/wares/_sn/success" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/allgame",
    component: _a24ccb58,
    name: "allgame"
  }, {
    path: "/auctionPage",
    component: _0ed1506f,
    name: "auctionPage"
  }, {
    path: "/black",
    component: _7e8931fc,
    name: "black"
  }, {
    path: "/center",
    component: _e26c8644,
    name: "center"
  }, {
    path: "/contact",
    component: _18ee2281,
    name: "contact"
  }, {
    path: "/Conversations",
    component: _04b6599e,
    name: "Conversations"
  }, {
    path: "/dealshow",
    component: _51e8dd0a,
    name: "dealshow"
  }, {
    path: "/forgetPassword",
    component: _4d9775f7,
    name: "forgetPassword"
  }, {
    path: "/gj-wzry",
    component: _e388e9e6,
    name: "gj-wzry"
  }, {
    path: "/jump",
    component: _689fdc3d,
    name: "jump"
  }, {
    path: "/kfyz",
    component: _54fc0757,
    name: "kfyz"
  }, {
    path: "/log",
    component: _0c7df85d,
    name: "log"
  }, {
    path: "/login",
    component: _1eab57ea,
    name: "login"
  }, {
    path: "/news",
    component: _6dcee9e0,
    name: "news"
  }, {
    path: "/register",
    component: _78867e9c,
    name: "register"
  }, {
    path: "/test",
    component: _337d5aa1,
    name: "test"
  }, {
    path: "/wares",
    component: _7f92a50b,
    name: "wares"
  }, {
    path: "/Bargbuy/Babuy",
    component: _1d8f42b8,
    name: "Bargbuy-Babuy"
  }, {
    path: "/Bargbuy/Partialpayment",
    component: _33870578,
    name: "Bargbuy-Partialpayment"
  }, {
    path: "/black/blackList",
    component: _4d4ba1ed,
    name: "black-blackList"
  }, {
    path: "/black/center",
    component: _720b9ad5,
    name: "black-center"
  }, {
    path: "/black/releaseBlack",
    component: _f8efc990,
    name: "black-releaseBlack"
  }, {
    path: "/black/zhgs",
    component: _5170a69a,
    name: "black-zhgs"
  }, {
    path: "/center/buyers",
    component: _9b977784,
    name: "center-buyers"
  }, {
    path: "/center/sell",
    component: _7e9fc3cc,
    name: "center-sell"
  }, {
    path: "/dealshow/selltime",
    component: _2bd9f597,
    name: "dealshow-selltime"
  }, {
    path: "/fastGold/fastGoldOrder",
    component: _332085b7,
    name: "fastGold-fastGoldOrder"
  }, {
    path: "/gj-wzry/hpjyEvaluator",
    component: _3b23bdd3,
    name: "gj-wzry-hpjyEvaluator"
  }, {
    path: "/specilPage/createAccountInfo",
    component: _1907fc73,
    name: "specilPage-createAccountInfo"
  }, {
    path: "/specilPage/downloadApp",
    component: _8bc5b9e6,
    name: "specilPage-downloadApp"
  }, {
    path: "/specilPage/hpjyEvaluator",
    component: _87e27ca8,
    name: "specilPage-hpjyEvaluator"
  }, {
    path: "/center/activity/play",
    component: _495da3a7,
    name: "center-activity-play"
  }, {
    path: "/center/assets/assetsFinancing",
    component: _377f0cbd,
    name: "center-assets-assetsFinancing"
  }, {
    path: "/center/buyers/BuyBargain",
    component: _adcaefe0,
    name: "center-buyers-BuyBargain"
  }, {
    path: "/center/buyers/buyBargainGoods",
    component: _45abdc76,
    name: "center-buyers-buyBargainGoods"
  }, {
    path: "/center/buyers/buyComplaint",
    component: _e8e376ee,
    name: "center-buyers-buyComplaint"
  }, {
    path: "/center/buyers/buyFastGold",
    component: _0022cfee,
    name: "center-buyers-buyFastGold"
  }, {
    path: "/center/buyers/buyFastGoldDetail",
    component: _4bd34942,
    name: "center-buyers-buyFastGoldDetail"
  }, {
    path: "/center/buyers/buyGoodsOrder",
    component: _437fc22a,
    name: "center-buyers-buyGoodsOrder"
  }, {
    path: "/center/buyers/buyGuarantee",
    component: _7ed2cfe6,
    name: "center-buyers-buyGuarantee"
  }, {
    path: "/center/buyers/buyLikedGoods",
    component: _b3ec588a,
    name: "center-buyers-buyLikedGoods"
  }, {
    path: "/center/buyers/buyOrderDetail",
    component: _6bde9d9d,
    name: "center-buyers-buyOrderDetail"
  }, {
    path: "/center/buyers/buyOrderInfo",
    component: _66b49f0c,
    name: "center-buyers-buyOrderInfo"
  }, {
    path: "/center/buyers/buySeckillOrder",
    component: _61efc01e,
    name: "center-buyers-buySeckillOrder"
  }, {
    path: "/center/buyers/lotteryRecords",
    component: _2352257d,
    name: "center-buyers-lotteryRecords"
  }, {
    path: "/center/buyers/sellerOrderInfo",
    component: _3124056e,
    name: "center-buyers-sellerOrderInfo"
  }, {
    path: "/center/extend/extendMyExtension",
    component: _a09859aa,
    name: "center-extend-extendMyExtension"
  }, {
    path: "/center/extend/qrCode",
    component: _6c23deec,
    name: "center-extend-qrCode"
  }, {
    path: "/center/extend/sellgooSn",
    component: _561b833c,
    name: "center-extend-sellgooSn"
  }, {
    path: "/center/feedback/complaintcustomerservice",
    component: _409b8016,
    name: "center-feedback-complaintcustomerservice"
  }, {
    path: "/center/feedback/feedbackandsuggestion",
    component: _56955e9d,
    name: "center-feedback-feedbackandsuggestion"
  }, {
    path: "/center/feedback/feedbackrecording",
    component: _43302373,
    name: "center-feedback-feedbackrecording"
  }, {
    path: "/center/log/history",
    component: _77699018,
    name: "center-log-history"
  }, {
    path: "/center/myself/accountSecurity",
    component: _f66c9a7e,
    name: "center-myself-accountSecurity"
  }, {
    path: "/center/myself/buyIdentity",
    component: _458139f8,
    name: "center-myself-buyIdentity"
  }, {
    path: "/center/myself/cashWithdrawal",
    component: _240d4164,
    name: "center-myself-cashWithdrawal"
  }, {
    path: "/center/myself/changeEmail",
    component: _555f6e60,
    name: "center-myself-changeEmail"
  }, {
    path: "/center/myself/changePayPassword",
    component: _d42384b2,
    name: "center-myself-changePayPassword"
  }, {
    path: "/center/myself/changePhone",
    component: _ccb1b19c,
    name: "center-myself-changePhone"
  }, {
    path: "/center/myself/depositWithdraw",
    component: _655ba45c,
    name: "center-myself-depositWithdraw"
  }, {
    path: "/center/myself/identityAuthentication",
    component: _096bd0f2,
    name: "center-myself-identityAuthentication"
  }, {
    path: "/center/myself/myselfInfo",
    component: _62fe47e2,
    name: "center-myself-myselfInfo"
  }, {
    path: "/center/myself/recharge",
    component: _15f0097a,
    name: "center-myself-recharge"
  }, {
    path: "/center/myself/thirdPartyBinding",
    component: _dd7bdb4c,
    name: "center-myself-thirdPartyBinding"
  }, {
    path: "/center/sell/sellBargainGoods",
    component: _ed118c68,
    name: "center-sell-sellBargainGoods"
  }, {
    path: "/center/sell/sellOrder",
    component: _45727016,
    name: "center-sell-sellOrder"
  }, {
    path: "/center/sell/sellOutGoods",
    component: _5b689c10,
    name: "center-sell-sellOutGoods"
  }, {
    path: "/center/sell/Sellreceive",
    component: _74240d2b,
    name: "center-sell-Sellreceive"
  }, {
    path: "/center/sell/sellSuccess",
    component: _44c23a4b,
    name: "center-sell-sellSuccess"
  }, {
    path: "/center/sell/sellTab",
    component: _225e8e46,
    name: "center-sell-sellTab"
  }, {
    path: "/center/myself/accountSecurity/changePassword",
    component: _7e88e90c,
    name: "center-myself-accountSecurity-changePassword"
  }, {
    path: "/center/myself/accountSecurity/changePhone",
    component: _36866e4d,
    name: "center-myself-accountSecurity-changePhone"
  }, {
    path: "/",
    component: _5503b4d3,
    name: "index"
  }, {
    path: "/black/:sn",
    component: _478eb9ac,
    name: "black-sn"
  }, {
    path: "/detail/:sn?",
    component: _ba19a0ec,
    name: "detail-sn"
  }, {
    path: "/JDetail/:sn?",
    component: _a08580a4,
    name: "JDetail-sn"
  }, {
    path: "/news/:code",
    component: _6f999ada,
    name: "news-code"
  }, {
    path: "/goods/:sn?/order",
    component: _797ce7eb,
    name: "goods-sn-order"
  }, {
    path: "/goods/:sn?/pay",
    component: _22075cf6,
    name: "goods-sn-pay"
  }, {
    path: "/goods/:sn?/success",
    component: _bfe3de40,
    name: "goods-sn-success"
  }, {
    path: "/wares/:sn/order",
    component: _7478e3aa,
    name: "wares-sn-order"
  }, {
    path: "/wares/:sn/pay",
    component: _4590f076,
    name: "wares-sn-pay"
  }, {
    path: "/wares/:sn/success",
    component: _1577b1c0,
    name: "wares-sn-success"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
