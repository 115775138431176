import axios from "../plugins/axios/index";
import qs from "qs";
import Cookie from "./cookie/cookie.js";
import md5 from "js-md5";
import cryptoJs from "../utils/cryptoJs/cryptoJs.js";
export async function restful(par = {}, url = "", tokenFalg = true) {
  let api = url;

  let data = null;
  let params = null;
  let method = "GET";

  if (par && par.method !== undefined) method = par.method;
  if (par && par.data !== undefined) data = par.data;
  if (par && par.params !== undefined) params = par.params;

  let headers = null;
  let r_url = url;
  headers = {};
  // r_url=url
  try {
    let token = "";
   
    if (par && par.req !== undefined) {
      if (
        par.req &&
        par.req.headers &&
        par.req.headers.host.indexOf("localhost") != -1
      ) {
        r_url = par.req.headers.origin + url;
      } else {
        r_url = "http://" + par.req.headers.host + url;
      }
      token = Cookie.getCookiesInServer(par.req, "token");
    } else {
      token = Cookie.getCookiesInClient("token");
      headers["X-CSRFToken"] = Cookie.getCookiesInClient("csrftoken");
    }

    if (token && token != undefined && token != "undefined" && tokenFalg) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      headers["Authorization"] = token;
    }
    if (
      method == "post" ||
      method == "POST" ||
      method == "PUT" ||
      method == "put"
    ) {
      // headers['Accept'] = 'application/json'
      data = qs.stringify(data);
    }
    headers["Content-Type"] = "application/x-www-form-urlencoded";

    try {
      let timer = parseInt(new Date().getTime() / 1000);
      let md5Value = md5("YCNAFDUAmd5:" + timer);
      headers["visitauth"] = cryptoJs.encrypt(
        md5Value,
        "9871267812345mn812345xyz"
      );
      headers["timeStamp"] = timer;
    } catch (e) {
      headers["visitauth"] = "";
      headers["timeStamp"] = "";
    }

    headers["Pragma"] = "no-cache";
    let result;
    if (method === "GET") {
      result = await axios({
        url: r_url,
        method,
        params,
        headers,
        withCredentials: true,
      });
    } else {
      result = await axios({
        url: r_url,
        method,
        data,
        headers,
        withCredentials: true,
      });
    }

    return result;
  } catch (error) {}
}
