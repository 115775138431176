
import {gameListHttp} from "~/api/comm";
import {Mutation, State} from 'vuex-class'
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// import {reMakeMsg} from "../../utils/reMakeMsg/reMakeMsg.js"
import axios from "axios";
import moment from "moment";
import {
  cancelUserRoomFixed,
  cancelUserSingleFixed,
  getUserKefuList,
  updateUserRoomFixed,
  updateUserSingleFixed
} from "~/api/chat";

@Component({
  name: "bonusAdd",
  components: {
    NewImg: require('../comm/newImg/index.vue').default,
    newImg2: require('~/components/comm/newImg/index2.vue').default,
  },
})
export default class bonusAdd extends Vue {
  public getCookie: any;
  public serviceList:Array<Object> = []
  public update: string = ''
  public userId: string = ''
  public phoneNum: string = ''
  public consoleCss: string = "font-weight:bold; color: #fff; border-radius: 5px; padding: 10px 25px;background: linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)"
  public openToService: any;

  @Emit('Close') send(needReload: boolean) {}

  mounted() {
    this.userId = this.getCookie('userId');
    this.phoneNum = this.getCookie('phoneNum');
    this.getUserKefuList();
  // reMakeMsg()
  }

  
  getUserKefuList(){
    if(this.userId) {
      var loading = this.$loading({
        lock: false,
        text: '聊天加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.7)'
      });
      let param = {
        userId: this.userId,
        page: 1,
        pagesize: 1000
      }
      getUserKefuList(param).then((res: any) => {
        this.serviceList = res.result;
        this.serviceList.forEach((item:any,index:number)=>{
          console.log(item.visitor,"个人")
          if(item.visitor.id!==0){
            item.name = item.visitor.group_show_name ? item.visitor.group_show_name:item.visitor.department_name?item.visitor.department_name : '暂无消息'
            item.hidden = item.visitor.user_is_read==1 && item.visitor.mes_type==2;
            item.last_message = item.visitor.last_message;
            item.last_message_time = item.visitor.last_message_time;
          }
          if(item.room.id!==0){
            item.name = item.room.name;
            if(item.room.buy_id==this.userId){
              item.hidden = item.room.buy_is_read!=2;
              item.last_message = item.room.buy_message_content;
              item.last_message_time = item.room.buy_message_time;
            }
            if(item.room.seller_id==this.userId){
              item.hidden = item.room.seller_is_read!=2;
              item.last_message = item.room.seller_message_content;
              item.last_message_time = item.room.seller_message_time;
            }
          }
        })
        loading.close()
      })
    }
  }

  changeOrder(item: any) {
    let param = {
      visitor_id: item.visitor.id ? item.visitor.id : item.room.id,
      user_id: this.userId
    }
    if (item.is_fixed) {
      if (item.room.id !== 0) {
        cancelUserRoomFixed(param).then((res: any) => {
          this.$message.warning("取消顶置")
          this.getUserKefuList()
          console.log("%c" + res.msg, this.consoleCss);
        })
      } else {
        cancelUserSingleFixed(param).then((res: any) => {
          this.$message.warning("取消顶置")
          this.getUserKefuList()
          console.log("%c" + res.msg, this.consoleCss);
        })
      }
    } else {
      if (item.room.id !== 0) {
        updateUserRoomFixed(param).then((res: any) => {
          this.$message.success(res.msg)
          this.getUserKefuList()
          console.log("%c" + res.msg, this.consoleCss);
        })
      } else {
        updateUserSingleFixed(param).then((res: any) => {
          this.$message.success(res.msg)
          this.getUserKefuList()
          console.log("%c" + res.msg, this.consoleCss);
        })
      }
    }
  }



@Emit()
 reMakeMsg(val:any)  {
console.log(val,"liaotian001")
if(!val) return val
if (val.includes('product[{')) {
    return '商品卡片';
} else if (val.includes('transfer[{')) {
    return '转接卡片';
} else if (val.includes('orderCode[{')) {
    return '订单卡片';
} else if (val.includes('urlCard[{')) {
    return '链接卡片';
} else if (val.includes('img[')) {
    return '图片';
} else if (val.includes('<img')) {
    return '图片'
}else if (val) {
    return val;
} else {
    return '.......';
}
}

  // 关闭弹窗
  @Emit()
  closePopBox(needReload: boolean) {
    this.send(needReload)
  }

  openKefuDetail(item: any) {

    item.hidden = false;
    // window.open("http://localhost:8008/chatIndex?customer_id=" + item.kefu.id + "&visitor_id=" + this.userId + "&visitor_name=" + this.phoneNum, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes")
    // window.open("http://169.254.221.13:8008/chatIndex?customer_id=" + item.kefu.id + "&visitor_id=" + this.userId + "&visitor_name=" + this.phoneNum, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes")
    if(item.room.id!==0){
      this.openToService(item.room.homeowner_id,null,item.room.id)
    }else{
      this.openToService(item.kefu.id,null,item.visitor.id)
    }
  }
}
