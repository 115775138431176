<template>
    <div class="floatLeft-box" v-if="false">
        <div class="floatLeft-box-list" style="margin-top: 10px;">
            <a href="https://space.bilibili.com/3493075823364113" target="_blank">
                <img src="~/static/image/floatLeft/bilibili.png" alt="">
            </a>
            <span>哔哩哔哩</span>
            <div class="box-list-buout"></div>
        </div>

        <div class="floatLeft-box-list" style="margin-top: 10px;">
            <a href="https://weibo.com/2142583132?refer_flag=1001030103_" target="_blank">
                <img src="~/static/image/floatLeft/weibo.png" alt="">
            </a>
            <span>微博</span>
            <div class="box-list-buout"></div>
        </div>

        <div class="floatLeft-box-list" style="margin-top: 10px;">
            <a href="https://www.douyin.com/user/MS4wLjABAAAAHjHHQlaE36eQvruoe65bgAHrDzgwhhunelV67WOLRTk" target="_blank">
                <img src="~/static/image/floatLeft/douyin.png" alt="">
            </a>
            <span>抖音</span>
            <div class="box-list-buout"></div>
        </div>

        <div class="floatLeft-box-list" style="margin-top: 10px;">
            <a href="" target="_blank">
                
            </a>
            <span></span>
        </div>

        <div class="floatLeft-box-list" style="margin-top: 10px;">
            <a href="" target="_blank">
                
            </a>
            <span></span>
        </div>


        <div class="floatLeft-box-but" @click="toTop">
            <img  src="~static/image/floatMenu/zhiding-hj.png" />
        </div>

        



    </div>
</template>

<script>
    import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";

    @Component({
    name: "floatLeft1",
    components: {
    
    },
    })

    export default class floatLeft1 extends Vue {
        
    @Emit()
  //返回顶部
  toTop() {
    window.scrollTo(0, 0);
  }
    }
</script>

<style lang="less" scoped>
.floatLeft-box{
    width: 70px;
    min-height: 500px;
    background-color: #FFFFFF;
    position: fixed;
    border-radius: 12px;
    top: 35%;
    left: 17px;
    z-index: 90;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 3px 5px 0px rgba(212, 217, 219, 0.56);

    .floatLeft-box-list{
        width:100% ;
        height: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: #999999;
        font-weight: bolder;

       a img{
            margin-top:21px;
            width: 30px;
            height: 30px;
        }

        span{
            display: block;
            height: 12px;
            width: 100%;
            line-height: 12px;
            text-align: center;
            font-size: 12px;
            
        }

        .box-list-buout{
            width: 24px;
            height: 1px;
            background: #DEDEDE;
            margin-top: 18px;
            margin-bottom: 6px;
        }

    }

    .floatLeft-box-list:hover{
            color: #379AFF;
        }



    .floatLeft-box-but{
        width: 100%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img{
            width:26px;
            height:26px;
        }

    }


}
</style>