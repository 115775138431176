import { restful } from '../utils/restful'

// 发送短信验证码
export function sendSmsCodeHttp(par = {}) {
  var url = '/api/comm/sms'
  return restful(par, url,false)
}

// 游戏列表
export function gameListHttp(par = {}) {
  var url = '/api/comm/gameList'
  return restful(par, url,true)
}

// 游戏筛选列表
export function gamefilterHttp(par = {}) {
  var url = '/api/comm/gamefilter'
  return restful(par, url,false)
}

// 商品类型列表
export function goodsTypeListHttp(par = {}) {
  var url = '/api/backstage/dst/goodsTypeList'
  return restful(par, url,false)
}

export function dealGamefilter(datas={}) {
  let bbb={
    1:{id:1,fieldSort:0},
    2:{id:2,fieldSort:1},
    3:{id:3,fieldSort:3},
    4:{id:4,fieldSort:2},
    5:{id:5,fieldSort:5},
    6:{id:6,fieldSort:4},
  }
  if (datas) {
    let aaa=datas
    for (let i in aaa) {
      for (let j in aaa) {
        if (aaa[j] && aaa[Number(j)+1]) {
          if (aaa[j].fieldSort<aaa[Number(j)+1].fieldSort) {
            let temp=aaa[j]
            aaa[j]=aaa[Number(j)+1]
            aaa[Number(j)+1]=temp
          }
        } else if (aaa[j] && aaa[Number(j)+2]) {
          if (aaa[j].fieldSort<aaa[Number(j)+2].fieldSort) {
            let temp=aaa[j]
            aaa[j]=aaa[Number(j)+2]
            aaa[Number(j)+2]=temp
          }
        } else if (aaa[j] && aaa[Number(j)+3]) {
          if (aaa[j].fieldSort<aaa[Number(j)+3].fieldSort) {
            let temp=aaa[j]
            aaa[j]=aaa[Number(j)+3]
            aaa[Number(j)+3]=temp
          }
        }
      }
    }
    return aaa
  } else {
    return {}
  }
}

// 获取上传图片的临时token
export function imgUploadTokenHttp(par = {}) {
  var url = '/api/comm/imagesTempUploadToken'
  return restful(par, url,true)
}

// 获取上传图片的真实地址
export function imagesRealAddressHttp(par = {}) {
  var url = '/api/comm/imagesRealAddress'
  return restful(par, url,true)
}

// 实名认证
export function certificateInfoHttp(par = {}) {
  var url = '/api/comm/certificateInfo'
  return restful(par, url,true)
}

// 查询实名认证资料信息
export function queryCertificateInfoHttp(par = {}) {
  var url = '/api/comm/queryCertificateInfo'
  return restful(par, url,true)
}

// 获取新闻列表
export function getNewsListHttp(par = {}) {
  var url = '/api/comm/getNews'
  return restful(par, url,true)
}

// 获取找回视频列表
export function dyVideoJumpManage(par = {}) {
  var url = '/api/comm/dyVideoJumpManage/getList'
  return restful(par, url,true)
}

// 获取用户隐私协议
export function getNewsInfoHttp(par = {}) {
  var url = '/api/comm/newsInfo'
  return restful(par, url,true)
}

// 获取王者荣耀英雄皮肤数据
export function getKrHeroSkinHttp(par = {}) {
  var url = '/api/evaluation'
  return restful(par, url,true)
}

// 获取上传图片的token
export function uploadQiniuTokenHttp(par = {}) {
  var url = '/api/comm/qiniuToken'
  return restful(par, url,true)
}

// 查询通用配置列表
export function getGeneralConfigHttp(par = {}) {
  var url = '/api/goods/getDistGamedsAdverList'
  return restful(par, url, false)
}



// 查询通用配置列表
export function createInviteCodeRelate(par = {}) {
  var url = '/api/comm/createInviteCodeRelate'
  return restful(par, url, false)
}


//ip获取
export function ipinfo(par={}) {
  var url = '/api/comm/ipInfo'
  return restful(par, url,true)
}


//ip获取
export function updateLastLogTime(par={}) {
  var url = '/api/comm/updateLastLogTime'
  return restful(par, url,true)
}

