import { render, staticRenderFns } from "./indexLayout.vue?vue&type=template&id=24598928&scoped=true"
import script from "./indexLayout.vue?vue&type=script&lang=ts"
export * from "./indexLayout.vue?vue&type=script&lang=ts"
import style0 from "./indexLayout.vue?vue&type=style&index=0&id=24598928&prod&scoped=true&lang=less"
import style1 from "./indexLayout.vue?vue&type=style&index=1&id=24598928&prod&lang=less"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24598928",
  null
  
)

export default component.exports