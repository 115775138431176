import { restful } from '../utils/restful'
import  moment from 'moment';
import secondtitle from "~/utils/secondtitle.js"
moment.locale("zh-cn");

//获取商品信息或者商品列表
export function goodsListHttp(par = {}) {
  var url = '/api/goods/getGoodsList'
  return restful(par, url,true)
}

//获取秒杀商品信息或者商品列表
export function getSeckillList(par = {}) {
  var url = '/api/goods/getSeckillListFn'
  return restful(par, url,false)
}

//创建秒杀商品订单
export function createSeckillOrder(par = {}) {
  var url = '/api/goods/createSeckillOrderFn'
  return restful(par, url,false)
}

//获取我的秒杀订单
export function queryUserSeckillOrderList(par = {}) {
  var url = '/api/user/queryUserSeckillOrderList'
  return restful(par, url,false)
}

//获取秒杀时间的场次
export function querySeckillTimeList(par = {}) {
  var url = '/api/goods/querySeckillTimeList'
  return restful(par, url,false)
}




//获取商品信息或者商品列表
export function goodsInfoHttp(par = {}) {
  var url = '/api/goods/goodsInfo'
  return restful(par, url,true)
}

export function dealGoodsData(datas={}) {
  let listDataConfig={
    "createTime":"createTime","price":"price","crossName":"crossName"
  }
  if (datas.goodsList) {
    for (let key in datas.goodsList) {
      for (let key2 in datas.goodsList[key]) {
        if (datas.goodsList[key] && datas.goodsList[key][key2] && listDataConfig[key2]) {
          if (key2 == 'createTime') {
            datas.goodsList[key]['createTime']=moment(datas.goodsList[key][key2]).format('YYYY-MM-DD');
          } else if (key2=='price') {
            // 金额操作，小数取整加1，整数不操作
            // if ((datas.goodsList[key]['price'] && !String(datas.goodsList[key]['price']).includes('.')) || (datas.goodsList[key]['price'] &&  String(datas.goodsList[key]['price']).includes('.00'))) {
            //   datas.goodsList[key]['price']= parseInt(Number(datas.goodsList[key]['price'])+0).toFixed(2)
              datas.goodsList[key]['price']= datas.goodsList[key]['price'].toFixed(2)
            // } else {
            //   datas.goodsList[key]['price']= parseInt(Number(datas.goodsList[key]['price'])+1).toFixed(2)
            // }
          } else if (key2=='crossName') {
            if ((datas.goodsList[key]['crossName'])){
              if (String(datas.goodsList[key]['crossName']).indexOf('DNF')>-1) { //地下城
                datas.goodsList[key]['crossName']=String(datas.goodsList[key]['crossName']).replace('DNF','')
              } else {
                datas.goodsList[key]['crossName']=String(datas.goodsList[key]['crossName'])
              }
            } else {
              datas.goodsList[key]['crossName']=""
            }
          }
        }
      }
    }
  } else {
    for (let key in datas) {
      if (datas[key] && listDataConfig[key]) {
        if (key == 'createTime') {
          datas['createTime']=moment(datas['createTime']).format('YYYY-MM-DD');
        } else if (key=='price') {
          // 金额操作，小数取整加1，整数不操作
          // if ((datas['price'] && !String(datas['price']).includes('.')) || (datas['price'] &&  String(datas['price']).includes('.00'))) {
          //   datas['price']= parseInt(Number(datas['price'])+0).toFixed(2)
            datas['price']= datas['price'].toFixed(2)
          // } else {
          //   datas['price']= parseInt(Number(datas['price'])+1).toFixed(2)
          // }
        } else if (key=='crossName') {
          if ((datas['crossName'])){
            if (String(datas['crossName']).indexOf('DNF')>-1) { //地下城
              datas['crossName']=String(datas['crossName']).replace('DNF','')
            } else {
              datas['crossName']=String(datas['crossName'])
            }
          } else {
            datas['crossName']=""
          }
        }
      }
    }
  }

  // console.log(datas, '处理后获取的商品列表数据结果')
  return datas
}

// 大家都在看
export function mostViewGoodsHttp(par = {}) {
  var url = '/api/goods/mostViewGoodsList'
  return restful(par, url,false)
}

//商品上架配置
export function goodsUpperConfHttp(par = {}) {
  var url = '/api/goods/getGoodsUpperConf'
  return restful(par, url,true)
}

//商品上架
export function releaseGoodsHttp(par = {}) {
  var url = '/api/goods/releaseGoods'
  return restful(par, url,true)
}

//商品更新
export function updatetGoodsHttp(par = {}) {
  var url = '/api/goods/updatetGoods'
  return restful(par, url,true)
}

//自己发布的商品
export function publishGoodsListHttp(par = {}) {
  var url = '/api/goods/selfPublishGoodsList'
  return restful(par, url,true)
}

//每日成交订单列表【日期dayTime:2020-06-13】
export function dailyGoodsListHttp(par = {}) {
  var url = '/api/goods/currentdayDealGoodsList'
  return restful(par, url,true)
}

//商品下架
export function pushOrPullGoodsHttp(par = {}) {
  var url = '/api/goods/pushOrPullGoods'
  return restful(par, url,true)
}

//只修改自己发布的商品价格
export function onlySetupGoodsPriceHttp(par = {}) {
  var url = '/api/goods/onlySetupGoodsPrice'
  return restful(par, url,true)
}

//买家砍价
export function bargainingHttp(par = {}) {
  var url = '/api/goods/bargaining'
  return restful(par, url,true)
}

//卖家同意砍价
export function sellerAgreedBargainHttp(par = {}) {
  var url = '/api/goods/sellerAgreedBargain'
  return restful(par, url,true)
}

//砍价列表  typeIndex：0 买家 1 卖家
export function bargainingListHttp(par = {}) {
  var url = '/api/goods/bargainingList'
  return restful(par, url,true)
}

//商品广告
export function goodsadvert(par={}){
  var url = '/api/goods/getNadvertList'
  return restful(par, url,true)
}
//口令获取
// export function checkAttentionStatus(par={}){
//   var url = '/api/goods/checkAttentionStatus'
//   return restful(par, url,true)
// }

export function checkAttentionStatus(par={}){
  var url = '/api/user/checkAttentionStatus'
  return restful(par, url,true)
}


//砍价定金
// export function Bargainingdeposit (par= {}){
//   var url = '/api/comm/bargainDeposit'
//   return restful(par, url,true)
// }

export function Bargainingdeposit (par= {}){
  var url = '/api/user/bargainDeposit'
  return restful(par, url,true)
}

//生成砍价订单
export function Bargainingorderfor (par= {}){
  var url = '/api/order/bargainOrder'
  return restful(par, url,true)
}

//生成砍价记录
export function Bargainingrecord (par= {}){
  var url = '/api/order/userBargainLog'
  return restful(par, url,true)
}

//同意砍价
export function Bargainingagree (par= {}){
  var url = '/api/user/sellerAgreedBargain'
  return restful(par, url,true)
}

//获取资金明细
export function getCapitalDetail (par= {}){
  var url = '/api/user/userBalanceLog'
  return restful(par, url,true)
}


//查看砍价订单
// export function lookBargainlist (par= {}){
//   var url = '/api/user/getUserBargainGoodsList'
//   return restful(par, url,true)
// }

// //取消砍价
export function lookBargainlist (par= {}){
  var url = '/api/user/getUserBargainGoodsList'
  return restful(par, url,true)
}


//退定金
export function cancelBargain (par= {}){
  var url = '/api/user/buyCancelBargainGoods'
  return restful(par, url,true)
}


//判断商品是否是砍价商品
export function QueryKAJgoods(par={}) {
  var url = '/api/user/userBargain'
  return restful(par, url,true)
}

//提现获取支付随机因子
export function getmoney(par={}) {
  var url = '/api/lianlianpay/getLianlianRandom'
  return restful(par, url,true)
}


//提现确认
export function withdrawApply(par = {}) {
  var url = '/api/lianlianpay/withdrawApply'
  return restful(par, url ,true)
}
//提现确认
export function getLianLianInfo(par = {}) {
  var url = '/api/user/getLianlianInfo'
  return restful(par, url ,true)
}




//提现确认
export function validationSms(par = {}) {
  var url = '/api/backstage/lianlian/validationSms'
  return restful(par, url ,true)
}


//卖家取消砍价
export function Sellercancelsbargaining(par = {}) {
  var url = '/api/user/sellerCancelBargainGoods'
  return restful(par, url ,true)
}


//开户
export function openacctApply(par = {}) {
  var url = '/api/lianlianpay/openacctApply'
  return restful(par, url ,true)
}

 //连连用户信息同步

export function synchronousUserOne(par = {}){
  var url = '/api/lianlianpay/synchronousUserOne'
  return restful(par, url ,true)
}

//连连密码找回验证
export function findPasswordVerify(par = {}){
  var url = '/api/lianlianpay/findPasswordVerify'
  return restful(par, url ,true)
}




export function findPasswordApply(par = {}){
  var url = '/api/lianlianpay/findPasswordApply'
  return restful(par, url ,true)
}

export function transferMorepyee(par = {}){
  var url = '/api/lianlianpay/transferMorepyee'
  return restful(par, url ,true)
}



export function getChargeServicesList(par = {}){ //获取游戏收费标准
  var url = '/api/comm/getChargeServicesList'
  return restful(par, url ,true)
}


export function getWowRegion(par = {}) {
  var url = '/api/goods/getWowRegion'
  return restful(par, url,false)
}