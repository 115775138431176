// state
import { MutationTree } from "vuex";


const state = {
  distributorsObject: {},//分销站信息
  footerImgArray: [],//底部资质信息
  topMenuArray: 0,//导航栏
  gamefilterArray: {},//游戏筛选
  hotGamesArray: [],//热门游戏列表
  gameListArray: [],//所有游戏列表
  fastGoldOrder:{},//快速金币订单
  editGoodsObject:{},//重新编辑商品保存数据
  secondTitleArray:[],//游戏二级标题
};

// vuex
const mutations: MutationTree<any> = {
  // 分销站信息
  SET_TOKEN_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.distributorsObject = null;
    state.distributorsObject = res;
  },

  // 底部资质信息
  SET_FOOTERIMG_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.footerImgArray = null;
    state.footerImgArray = res;
  },

  // 导航栏
  SET_TOPMENU_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.topMenuArray = null;
    state.topMenuArray = res;
  },

  // 游戏筛选
  SET_GAMEFILTER_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
   state.gamefilterArray = null;
    state.gamefilterArray = res;
  },

  // 热门游戏列表
  SET_HOTGAMES_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.hotGamesArray = null;
    state.hotGamesArray = res;
  },

  // 所有游戏列表
  SET_GAMELIST_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.gameListArray = null;
    state.gameListArray = res;
  },

  // 快速金币订单
  SET_FASTGOLDORDER_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.fastGoldOrder = null;
    state.fastGoldOrder = res;
  },

  // 重新编辑商品保存数据
  SET_EDITGOODS_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.editGoodsObject = null;
    state.editGoodsObject = res;
  },

  // 游戏二级标题
  SET_SECONDTITLE_DATA(
    state: any,   //对应封装里面的state
    res: any      //对应封装里面的payload
  ): void {    //void与java中类似，返回值的含义
    state.secondTitleArray = null;
    state.secondTitleArray = res;
  },
}

// getters
const getters = {}
// actions
const actions = {

}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
