/*
 * @Author: Arvin 
 * @Date: 2019-02-23 14:31:59 
 * @Last Modified by:   Arvin 
 * @Last Modified time: 2019-02-23 14:31:59 
 */

import Vue from "vue"

const bus = {
    install(Vue, options) {
        Vue.prototype.$bus = new Vue()
    }
}
export default () => {
    Vue.use(bus)
}