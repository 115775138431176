
  import { State, Getter, Action, Mutation, namespace } from "vuex-class";
  import { Component, Vue, Emit, Prop, Watch} from 'vue-property-decorator'
  @Component({
    name:"newImg",
  })
  export default class newImg extends Vue {
    @State((state) => state.web.distributorsObject) distributorsObject!: any;
    @Prop({default:true}) lazy_?: boolean //是否懒加载
    @Prop({default:''}) url_?: string //图片地址
    @Prop({default:false}) waterMark_?: boolean //是否打水印,1:分销商品，0：自有商品
    @Prop({default:''}) width_?: boolean //图片压缩比
    @Prop({default:''}) height_?: boolean //图片压缩比
    private nowPage:number=1
    private imgTitle:string='' //图片标题
    private waterMarkPosition:Array<any>=["east", "west", "south", "north", "southeast", "southwest", "northeast", "northwest", "center", "tile"] //水印对齐方式
    private url:string=''
    private newUrl:string='https://oss1.hk1686.com/'
    mounted(){
      // if (window && window.location.host && this.waterMark_) {
      //   this.newUrl=`http://img.${window.location.host}.dianjinghou.cn/`
      // } else {
      //   this.newUrl='https://oss1.hk1686.com/'
      // }
      // this.getUrl()
    }
    @Emit()
    getUrl() {
      console.log(this.url_,'========url_=====哈哈哈哈哈=====')
      // let newUrl=`http://img.${window.location.host}.dianjinghou.cn/`
      let query:string=''
      // if (this.width_) {
      //   query=`${query}&width=${this.width_}`
      // }
      // if (this.height_) {
      //   query=`${query}&height=${this.height_}`
      // }
      // query=`${query}&watermark_flag=1&position=center`

      // query=query.replace('&','?')
      // 确定添加水印 且后台设置了水印 且链接是我们自己的图片链接
      if(this.waterMark_ && this.distributorsObject && this.distributorsObject.distBaseConf.watermark && this.url_ && this.url_.indexOf('nocs-big')==-1) {   // 商品图片需要打水印
      //  &&
        let watermark = this.distributorsObject.distBaseConf.watermark
        // 看链接是否是我们自己的链接
        if (this.url_ && this.url_.indexOf('http')>-1) {   // 全链接类型的地址
          if(this.url_ && this.url_.indexOf('dianjinghou.com') > -1) {    // 自己的链接
            return `${this.url_}${watermark}`
          } else {                                                 // 别人的链接
            return `${this.url_}`
          }
        } else {
          return `${this.newUrl}${this.url_}${watermark}`
        }
      } else {
        // query=query.replace('&','?')
        if (this.url_ && this.url_.indexOf('http')>-1) {
          return `${this.url_}`
        } else {
          return `${this.newUrl}${this.url_}`
        }
      }
    }
  }
