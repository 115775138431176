import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import web from './web'



const store = () => {
  return new Vuex.Store({
    modules: {
      web
    }
  })
}
if(process.browser) {
  // window.console = {
  //   log: () => {},
  //   dir: () => {},
  //   warn: () => {}
  // }
  window.onload = function(){
    var timer = null; //声明定时器变量
    document.onkeydown = function(event) {
      let event1 = event || window.event;
      let keycode = event1.keyCode || event1.which;
      if(keycode == 116 && event3.key != 't'){
        // alert('触发F5按键');
        if(event1.preventDefault){ //chrome、firefox、IE9+
          console.log('进来1');
          event1.preventDefault();
        }else{//IE8-
          event1.keyCode = 0;
          event1.returnValue = false;
        }
      }
    }
    document.onkeypress = function(event) {
      let event3 = event || window.event;
      let keycode = event3.keyCode || event3.which;
      // console.log('slkjdfkljkljnkl',event3);
      if(keycode == 116 && event3.key != 't'){
        // alert('触发F5按键');
        if(event3.preventDefault){ //chrome、firefox、IE9+
          console.log('进来2',keycode);
          event3.preventDefault();
        }else{//IE8-
          event3.keyCode = 0;
          event3.returnValue = false;
        }
      }
    }
    document.onkeyup = function(event){
      clearTimeout(timer); //每次按键先清除定时器，避免定时器重复多开
      let event2 = event || window.event;
      let keycode = event2.keyCode || event2.which;
      if(keycode == 116 && event3.key != 't'){
        // alert('触发F5按键');
        if(event2.preventDefault){ //chrome、firefox、IE9+
          console.log('进来3');
          event2.preventDefault();
        }else{//IE8-
          event2.keyCode = 0;
          event2.returnValue = false;
        }
        // timer = setTimeout(function(){ //设置一个定时器，3秒后清除按键事件，恢复按F5刷新功能
        // alert('恢复刷新');
        // document.onkeyup = null;
        window.location.reload()
        // console.log('刷新');
        // }, 3000);
      }
    };
  };
  // window.console.log = () => {}
  window.console.error = () => {}
  window.console.dir = () => {}
  window.console.warn = () => {}

} else {
  console.log = () => {}
  console.dir = () => {}
  console.warn = () => {}
}
export default store
