
  import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {getChargeServicesList } from '~/api/goods'
  import {wechatLoginHttp} from "~/api/operation";
  import {getUserInfoHttp} from "~/api/user";
  @Component({
    name:"djhNav8",
    components: {
      wechatLogin: require('~/components/comm/djhModal/wechatLogin.vue').default,
      
    },
  })
  export default class djhNav8 extends Vue {
    public setCookie:any //设置cookie
    public getCookie:any //获取cookie
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any //接口异常抛出
    private isLogined : Boolean = false // 是否已登录
    private nickName : string = '' // 是否已登录
    private wechatShow:boolean = false
    private loading:boolean = true
    private wechatValue:any = { url:'', code:''}
    private timer:any
    public feedialog: boolean = false;
    public datalist:any = []
    public feestandard:any
    public chargeinputData:string = ''
    public activeindex:string = ''
    public showCharging:boolean = false
    public typeteles:any = [
      {name:'挂牌代售',value:0},
      {name:'包赔中介',value:1},
      {name:'普通中介',value:2},
    ]


    mounted(){
      this.nickName=this.getCookie('nickName')
      // console.log(this.getCookie('nickName'),'=======this.getCookie()========')
      
      setTimeout(()=>{
        console.log(this.$store.state.web.distributorsObject,'=======distributorsObject========')
      },50)
      if (this.getCookie('token')) {
        this.isLogined=true
      }
    }

  

    //关闭定时任务
    @Emit()
    closeLoginModal(msg: boolean){
      this.wechatShow=msg
      clearInterval(this.timer)
    }

    chengdialog() {
    this.showCharging = true
    this.Qurygemg()
  }

  clone(){
    this.showCharging = false
  }

@Emit()
gohistory(){
  console.log("66")
  // this.$router.push({path:'/history'})
}

@Emit()
handleClick(v:any,item:any){

  
  this.activeindex = v.index

  console.log(v,item,'++++++++++++++点击了');
  
}

@Emit()
 async Qurygemg(){
    let res = await getChargeServicesList({ params: { 
      isAll: 1 ,
      gameId:'',
    } })
    
    if(res?.data.code == 0){
    this.feestandard = res.data.data.data.filter( (item:any) =>{
      return item.DataJson = JSON.parse(item?.DataJson)
    })
    //this.datalist = [...this.feestandard]
    this.datalist = this.feestandard.map((item:any) =>{
      return {
        ...item,
        select:0,
      }
    })

    
    console.log(this.feestandard,'=======this.feestandard========收费标准');
    
    }
  }


  selectedIndex(item:any,i:any) {

    console.log(item.select,i,'+++++++++++++++++++点击时');
      item.select = i
      
		}



  @Emit()
  Chargeinput(){ //关键字搜索
    this.datalist =  this.feestandard.filter((item:any)=>{
        item.select = 0
       return item?.GameName.toLocaleLowerCase().includes(this.chargeinputData.toLocaleLowerCase())
    })

   
    console.log(this.datalist,'=======this.datalist=========搜索结果');
    
   
  }

  


    @Emit()
    // 第三方登录
    async thirdLogin(type:string){
      this.wechatShow=false
      if (type=='qq') {

      } else {
        try {
          this.wechatShow=true
          let {data} = await wechatLoginHttp({method:'POST'})
          setTimeout(()=>{
            this.loading=false
          },400)
          this.wechatValue=data
          try {
            this.timer = setInterval(async () => {
              let { data } = await wechatLoginHttp({ method: 'GET', id:this.wechatValue.code+'u' })
              console.log(data,'==========data===========')
              if (data && data.token) {
                clearInterval(this.timer)
                this.setCookie('token', data.token)
                try {
                  let userData=await getUserInfoHttp()
                  if (this.errorToastFn(userData.data)) {
                    this.setCookie("nickName", userData.data.data.nickName)
                    this.setCookie("userId", userData.data.data.userId)
                    this.setCookie("actor", userData.data.data.actor)
                    this.setCookie("phoneNum", userData.data.data.phoneNum)
                  }
                  window.open('/', '_self')
                } catch (e) {
                  this.eventNotifyTost('提示', '网络超时', 'warning')
                }
              }
            }, 1500)
          }catch (error) {
            this.errorToastFn(error)
          }
        }catch (error) {
          this.errorToastFn(error)
        }
      }

    }
  }
