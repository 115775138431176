import Cookie from 'js-cookie'

//获取服务端cookie
let getCookiesInServer=function (req,key) {
  let service_cookie = {};
  req && req.headers.cookie && req.headers.cookie.split(';').forEach(function (val) {
    let parts = val.split('=');
    service_cookie[parts[0].trim()] = (parts[1] || '').trim();
  });
  return service_cookie[key];
}

//设置服务端cookie
let setCookieInServer=function(req,cname, cvalue, exdays=1){
  let d = new Date();
  d.setTime(d.getTime()+(exdays*24*60*60*1000));
  let expires = "expires="+d.toGMTString();
  req.headers.cookie = cname+"="+cvalue+"; "+expires;
}

//获取客户端cookie
let getCookiesInClient=function (key) {
  return Cookie.get(key) ? Cookie.get(key) : ''
}

//设置客服端cookie
let setCookiesInClient=function(key,value,exdays=1) {
  return Cookie.set(key,value,exdays*60 *60*1000);//过期时间 2分钟
}

//删除客服端cookie
let removeCookieInClient=function(key) {
  return Cookie.remove(key)
}

export default {
  getCookiesInServer,
  getCookiesInClient,
  setCookieInServer,
  setCookiesInClient,
  removeCookieInClient
}
