
  import { Component, Vue, Emit, Prop} from 'vue-property-decorator'
  import {queryVerifyCustomerHttp} from "~/api/cusSer";
  import { addBlackHttp } from "~/api/black"
  import { EventBus } from '~/utils/eventBus';
  @Component({
    name:"kefuVerifyServicePopup",
  })
  export default class kefuVerifyServicePopup extends Vue {
    @Prop(Boolean) isShow?: any
    private options:any={
      "navbar": false,
      hidden:()=> {
        // this.isShow=false
        (this.$parent as any).showBox = false
        let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
        body[0].setAttribute('style', 'overflow:auto')
      }
    }
    public checkForm:any;
    public errorToastFn:any;
    public eventMsgTost:any;
    public showPopup: any = false;
    private qqNum:string='';
    private wxNum:string='';
    private blackStr:string='';
    private dialogVisible:boolean=false;
    private infoObject:any={queryValue:'',queryType:1}; //要验证的数据

    mounted(){
      EventBus.$on('KefuVerify',() => {
        this.showPopup = true
      })
    }

    // beforeDestory() {
    //   EventBus.$off('KefuVerify')
    // }
    
    // @Emit()
    // hiddens(){
    //   this.isShow=false
    //   let body:any = document.querySelectorAll('body') as NodeListOf<HTMLElement>
    //   body[0].setAttribute('style', 'overflow:auto')
    // }
    // @Emit()
    // show (index:number) {
    //   const viewer = (this.$refs.viewer as any).$viewer
    //   viewer.view(index)
    //   viewer.show()
    // }
    @Emit()
    async formValidation(v:number) {
      if (v==1) {
        this.infoObject.queryType=1
        this.infoObject.queryValue=this.qqNum
      } else {
        this.infoObject.queryType=2
        this.infoObject.queryValue=this.wxNum
        //验证是不是中文字符
        var pattern = new RegExp("[\u4E00-\u9FA5]+");
        if(pattern.test(this.wxNum)){
          this.dialogVisible = true
          return
        }
      }
      let info:Array<any>=[
        {key:"queryValue",value:this.infoObject.queryValue?this.infoObject.queryValue:'',tip:'请填写客服号码'},
      ]
      //表单验证
      if (this.checkForm(info)) {
        try {
          let {data}:any=await queryVerifyCustomerHttp({params:this.infoObject})
          if (this.errorToastFn(data)) {
            console.log(data.data,!!data.data)
            let flag:string=''
            if (this.infoObject.queryType==1) {
              flag='QQ客服'
            } else {
              flag='微信客服'
            }
            if (data.data && !!data.data.distId) {
              this.eventMsgTost(`经验证：${flag}${this.infoObject.queryValue} 为真客服。`,'success');
            } else {
              this.eventMsgTost(`经验证：${flag}${this.infoObject.queryValue} 为假客服。`,'warning');
            }
          }
        } catch (error) {
          this.eventMsgTost('','系统繁忙中，请稍后再试！');
        }
      }

    }
    async checkBlack() {   // 验证黑名单
      if(!this.blackStr.trim()) {
        this.$message.warning('请输入需要查询的黑名单')
        return false
      }
      try {
        let distId = this.$store.state.web.distributorsObject.distId
        let {data}:any=await addBlackHttp({params:{distId:distId, keyWord:this.blackStr}})
        if (this.errorToastFn(data)) {
          if (data.data && data.data.list && data.data.list.length>0) {
            this.$alert('该用户已被拉入黑名单，请终止交易。', '存在违规', {
              confirmButtonText: '确定',
              callback: action => {
              }
            });
          } else {
            this.$alert('该用户未被拉入黑名单，如后续有违规请联系客服，避免线下交易。', '未违规', {
              confirmButtonText: '确定',
              callback: action => {
              }
            });
          }
        }
      } catch (error) {
        this.errorToastFn(error,'系统繁忙中，请稍后再试！');
      }
    }
    closeKefuVerify() {
      this.showPopup = false
      this.qqNum = ''
      this.wxNum = ''
      this.blackStr = ''
    }
    moveHandle() {
      return false
    }
  }
