
  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  //外部点击
  import '../../../utils/directive'
  @Component({
    name:"wechatLogin",
  })
  export default class wechatLogin extends Vue {
    @Prop(String) left?: '42%' // 微信登录弹框位置
    @Prop(String) top?: '32%' // 微信登录弹框位置
    @Prop(String) width?: '300px' // 微信登录弹框大小
    @Prop(Boolean) loading_?: true // 微信登录弹框loading
    @Emit('bindSend') send(msg: boolean){}; // send 处理给父组件传值的逻辑
    private num:number=0 //关闭方法执行次数
    mounted(){
    }
    @Watch("loading_")
    getLoading_(newVal: any) {
      // console.log(newVal,'========loading_==========')
      this.loading_=newVal
    }
    //子组件控制微信登录接口拉取是否停止
    @Emit()
    closeLoginModal(){
      this.num++
      let flag=false
      if (this.num%2==0) {
        flag=false
      } else {
        flag=true
      }
      this.send(flag)
    }

  }
