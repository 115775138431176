
  import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {getDistributorHttp} from "~/api/distributor";
  @Component({
    name:"djhFooter",
    components: {
      newImg2: require('~/components/comm/newImg/index2.vue').default,
    }
  })
  export default class djhFooter extends Vue {
    @Mutation private SET_FOOTERIMG_DATA!: any;
    @State(state => state.web.footerImgArray) footerImgArray!: any;
    @Prop(String) background_?: string
    @Prop(String) color_?: string
    @Prop(String) marginTop_?: string
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any

    private isShowJiShu: boolean = false//是否显示技术支持弹框
    private isMouseEnter: String = ''//技术支持弹框中电话/微信鼠标是否移入
    private footerImageList: any = [] //资质列表
    private isShow: boolean = false //是否显示资质列表，防止刷新时默认显示资质
    private newUrl:string='https://oss1.hk1686.com/'

    private youQingList:any=[] //友情链接
    mounted(){
      setTimeout(()=>{
        console.log(this.$store.state.web.distributorsObject,'=====distributorsObject-==哈哈哈哈=')
      },500)
      setTimeout(()=>{
        if (this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.botQuals.length==0) {
          // this.getFooterImg()
        } else {
          this.footerImageList=this.$store.state.web.distributorsObject.botQuals
          this.youQingList=this.$store.state.web.distributorsObject.notifBits
        }
        this.isShow=true
      },50)
    }
    @Watch("footerImgArray")
    getFooterImageList(newVal: any){
      this.footerImageList=newVal
      console.log(newVal,'========footerImgArray=1=======')
    }
    @Emit()
    getUrl(url:string) {
      if (url && url.indexOf('http')>-1) {
        return url
      } else {
        return `${this.newUrl}${url}`
      }
    }
    @Emit()
    async getFooterImg(){
      try {
        let {data}=await getDistributorHttp()
        if (this.errorToastFn(data)) {
          this.footerImageList=data.data.botQuals
          this.SET_FOOTERIMG_DATA(this.footerImageList)
          this.isShow=true
          console.log("---------------------------------------")
        } else {
          this.footerImageList=[]
          this.isShow=true
        }
      } catch (error) {
        this.footerImageList=[]
        this.isShow=true
      }
    }
    @Emit()
    mouseEnter(v:string){
      this.isMouseEnter=v
    }
  }
