import { restful } from '../utils/restful'

//添加黑名单
export function addBlackHttp(par = {}) {
  var url = '/api/black/'
  return restful(par, url ,true)
}

//最新黑名单列表
export function newDataHttp(par = {}) {
  var url = '/api/black/newData'
  return restful(par, url ,true)
}
