import secondtitle from "../config/secondtitle.json"

export default {
  filters: {
    goodsTitlefilter(goods) {
      // console.log(goods)
      if (goods.goodsTypeId!=2) {
        let gameId=goods.gameId
        if( gameId ){
          let titleArr=(secondtitle[String(gameId)] && secondtitle[String(gameId)][goods.goodsTypeId])?secondtitle[String(gameId)][goods.goodsTypeId]
            :secondtitle[String(gameId)][goods.goodsTypeId]?secondtitle[String(gameId)][goods.goodsTypeId]
              :secondtitle[String(gameId)][1]
          let title2=''
          for (let i=0;i<titleArr.length;i++) {
            if (goods[titleArr[i]]) {
              if (titleArr[i]=='game_jobs_name') {
                if (goods[titleArr[i]].indexOf('-')>-1) {
                  title2=title2+' '+goods[titleArr[i]].split('-')[1]
                } else {
                  title2=title2+' '+goods[titleArr[i]]
                }
              } else {
                if (titleArr[i]=='GameJobs_num') {//英雄
                  title2=title2+' '+'英雄'+goods[titleArr[i]]+'个'
                } else if (titleArr[i]=='skin_num') {//皮肤
                  title2=title2+' '+'皮肤'+goods[titleArr[i]]+'个'
                } else if (titleArr[i]=='rune') {//五级铭文
                  title2=title2+' '+'五级铭文'+goods[titleArr[i]]+'个'
                } else if (titleArr[i]=='role_level') {//角色等级
                  title2=title2+' '+goods[titleArr[i]]+'级'
                } else if (titleArr[i]=='single_ranking') {//单双排位
                  title2=title2+' '+'单双排位'+goods[titleArr[i]]
                } else if (titleArr[i]=='flexible_ranking') {//灵活组排
                  title2=title2+' '+'灵活组排'+goods[titleArr[i]]
                } else if (titleArr[i]=='num') {//库存
                  title2=title2+' '+'库存'+goods[titleArr[i]]
                } else {
                  title2=title2+' '+goods[titleArr[i]]
                }
              }
            }

          }
          return title2
        } else {
          let title2=''
          return title2
        }
      } else {
        return goods.smallTitleName
      }


    }
  },
}
