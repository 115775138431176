import Axios from "~/utils/chatAxios";
var qs = require('qs');
import Cookie from "~/utils/cookie/cookie";

function getCookie(dataStr = "token") {
  return Cookie.getCookiesInClient(dataStr)
}
export function getUserKefuList(params) {
  params = qs.stringify(params)
 
  // return  Axios.get(`/getUserKefuList?${params}`)
  return Axios.post(`/getUserKefuList?${params}`,null, {
    headers: {
      'shopToken': getCookie("token"), 
    },
  });
}

export function orderUpdate(params) {
  params = qs.stringify(params)
  return Axios.post(`/transpro/order/update`,params);
}

export function updateUserSingleFixed(params) {
  params = qs.stringify(params)
  return  Axios.post('/2/updateUserSingleFixed',params)
}

export function cancelUserSingleFixed(params) {
  params = qs.stringify(params)
  return  Axios.post('/2/cancelUserSingleFixed', params)
}

export function updateUserRoomFixed(params) {
  params = qs.stringify(params)
  return  Axios.post('/2/updateUserRoomFixed', params)
}

export function cancelUserRoomFixed(params) {
  params = qs.stringify(params)
  return  Axios.post('/2/cancelUserRoomFixed', params)
}

export const allocationCustomers = (params)=>{
  params = qs.stringify(params)
  return Axios.get(`/2/allocationCustomers?${params}`)
}

export const transRoom = (params)=>{
  params = qs.stringify(params)
  return Axios.get(`/2/transRoom?${params}`)
}

export function getFreeFeedbackKefu(params) {
  params = qs.stringify(params)
  return  Axios.get('/2/getFreeFeedbackKefu',params)
}

export function addFeedbackRoom(params) {
  params = qs.stringify(params)
  return  Axios.post('/2/addFeedbackRoom',params)
}

//获取房间号
export const visitorLogin = (params)=>{
  params = qs.stringify(params)
  return Axios.post(`/visitor_login?${params}`)
}



//砍价 根据客服ID和用户id  获取房间号。
export const getVisitorId = (params)=>{
  params = qs.stringify(params)
  return Axios.get(`/getVisitorId?${params}`)
}

export const updateBargainOrderVisitorId = (params)=>{
  params = qs.stringify(params)
  return  Axios.post('/updateBargainOrderVisitorId', params)
}
