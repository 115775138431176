
import axios from 'axios';

var httpErrorTost = false
const myaxios = axios.create({
  // ...
})
myaxios.interceptors.response.use(
  function (response) {
    httpErrorTost = false
   
    return response
  },
  (error) => {
   
    httpErrorTost = true
    if (process.env.VUE_ENV != 'server') {
      var isSelfPromptErrorMsg = window.localStorage.getItem('isSelfPromptErrorMsg')
      if (isSelfPromptErrorMsg != null && isSelfPromptErrorMsg > 0) {
        window.localStorage.removeItem('isSelfPromptErrorMsg')
        return Promise.reject(error.response)
      }
      if (error.response && httpErrorTost) {
        httpErrorTost = false
        switch (error.response.status) {
          case 400:
            // Notification.error({ title: '信息', messages: filterOneErrMsg(error.response.request.responseText) })
            break;
          case 401:
            // Notification.error({ title: '信息', message: '登陆失效!' })
            // if (progress.server)
            // window.location.href = "/login"
            break;
          case 403:
            // Notification.error({ title: '信息', message: error.response.data.detail })
            break;
          case 502:
            // Notification.error({ title: '信息', message: '网络超时' })
            // window.location.href = "/err"
            break;
          case 504:
            // window.location.href = "/err"
            // Notification.error({ title: '信息', message: '网络超时' })
            break;
        }
      }
    }
    return Promise.reject(error.response)
  })

export default myaxios
