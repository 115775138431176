
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { queryVerifyCustomerHttp } from '~/api/cusSer'
import { addBlackHttp } from '~/api/black'
import {getCustomerId} from "~/api/user";
import axios from "axios";
import {allocationCustomers, getUserKefuList} from "~/api/chat";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";
import { EventBus } from '~/utils/eventBus';
@Component({
  name: "floatMenu5",
  components: {
    newImg2: require("~/components/comm/newImg/index2.vue").default,
    chatList:require('~/components/chat/chatList.vue').default
  },
})
export default class floatMenu9 extends Vue {
  @Prop(Boolean) isShowDownloadDialog?: any
  @Prop({default:false}) isSubstationIndex_?: any
  @Prop(Object) userInfo_?: any
  public getCookie:any
  public openToService:any
  private openUrl:any
  private mobileRegular:any
  private qqRegular:any
  private openOnlineService:any
  private show: boolean = true
  private show1: number = 1;
  private isShowCusQr : boolean = false
  private tip : boolean = false
  private isShowGroupQr : boolean = false
  private QRCode: any;
  private hostQr: string = '';
  private userId:string='';
  public phoneNum: string = '';
  private isCheckCurstomer:boolean = true
  private inputStr: string = ''
  private inputWxStr: string = ''   // 因为刚开始只考虑了qq和黑号 微信后来添加 单独处理
  private isShowMask: boolean = false
  private gameId:any
  public KefuList:any
  private showPopBox: boolean=false;
  private loading:boolean = true;
  public timer:any = null;
  public downLoadOpacity: any = 0;
  mounted() {
    this.userId = this.getCookie('userId');
    this.phoneNum = this.getCookie('phoneNum');
    if(this.userId){
      this.getKefuList();
     this.timer = setInterval(()=>{
        this.getKefuList();
      },30000)
    }
    this.qRCodeFn();
  }
  @Emit()
  //返回顶部
  toTop() {
    window.scrollTo(0, 0);
  }
  @Emit()
  toServe()
  {
    let customerType;
    this.gameId=new URL(window.location.href).searchParams.get('gid')
    console.log(window.location.pathname);
    if(this.userId){
      if(this.gameId){
        this.Tocontact("1",this.gameId,this.userId)
      }
      if(window.location.pathname.includes('center/sell')){
        let sell_game_id=this.getCookie("sell_gameId")
        console.log("缓存里面的游戏ID",sell_game_id)
        if(sell_game_id){
          this.Tocontact("2",sell_game_id,this.userId)
        } else {
          this.openUrl('/contact')
        }
      }
      else {
        this.openUrl('/contact')
      }
      // if(window.location.pathname==='/center/sell'||window.location.pathname.includes('/allgame')||window.location.pathname.includes('/wares'))
    /*  if(window.location.pathname.includes('/wares'))
      {
        let customerType = "2";
        if(window.location.pathname.includes('/allgame')||window.location.pathname.includes('/wares'))
        {
          customerType = "1";
        }
        getCustomerId({params:{userId:userId,customerType:customerType}}).then((res:any)=>{
          // console.log(res.data.data);
          if(res.data.data.customerId&&res.data.data.ent_id)
          {
            window.open(`/chatIndex.html?ent_id=`+ res.data.data.entId+`&customer_id=`+ res.data.data.customerId +`&visitor_id=`+userId+`&visitor_name=`+phoneNum, '_blank')
          }else {
            this.$message.warning('当前所有客服处于繁忙状态，请稍后再试！敬请谅解！')
          }
        });
      }
      else
      {
        this.openUrl('/contact')
      }*/
    }else
    {
      this.$router.push('/login')
    }
  }
  kefuVerify() {
    EventBus.$emit('KefuVerify')
  }
  @Emit()
  Tocontact(customerType:any,gameId:any,userId:any){
    let phoneNum = this.getCookie('phoneNum');
    console.log("类型+游戏ID",customerType,gameId)
    allocationCustomers({userId:userId,customerType:2,gameId:gameId}).then((res:any)=>{
      if (res.result.customerId) {
        this.openToService(res.result.customerId)
      } else {
        this.$message.warning('当前所有客服处于繁忙状态，请稍后再试！敬请谅解！');
      }
    });
  }
  @Emit()
  erweimaShowHandle(flag:boolean) {
      this.isShowCusQr = flag
  }
  @Emit()
  joinGroupShowHandle(flag:boolean) {
      this.isShowGroupQr = flag
  }
  qRCodeFn() {
    let url = window.location.origin;
    this.QRCode.toDataURL(url, (err: any, url: any)=>{
        this.hostQr = url;
    });
  }
   // 打开售前在线客服
    openPreSaleSerLink() {
      if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink) {
        // window.open(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink, '_blank')
        this.openOnlineService(this.$store.state.web.distributorsObject.customerBlock.onlineSrvLink)
      } else if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.serviceNum) {
        window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.$store.state.web.distributorsObject.customerBlock.serviceNum}&site=qq&menu=yes`, '_blank')
      }
    }
    // 切换客服验证tab
    clickTabHandle(status:any) {
      this.inputStr = ''
      this.isCheckCurstomer = status
    }
    // 请求验证
    async goToCheck() {
      let noticeMsg = ''
      if(this.isCheckCurstomer) {
        if(!this.qqRegular.test((this.inputStr).trim())) {
          this.$message({
            // message: '经验证：12313131为假客服！',
            message: '请输入正确的客服QQ号！',
            offset: 530,
            duration: 2000,
            customClass: 'myMessageCss'
          })
          return
        } else {
          try {
            const {data} = await queryVerifyCustomerHttp({params:{queryType:1,queryValue:(this.inputStr).trim()}})
            if(data && data.code == 0 && Object.keys(data.data).length>0) {
              noticeMsg = `经验证：${this.inputStr}为真客服！`
            } else {
              noticeMsg = `经验证：${this.inputStr}为假客服！`
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 2000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        if(!this.inputStr) {
          this.$message({
            message: '请输入要查询的黑名单！',
            offset: 530,
            duration: 2000,
            customClass: 'myMessageCss'
          })
        } else {
          try {
            let distId = 110
            if(this.$store.state.web.distributorsObject.distId) {
              distId = this.$store.state.web.distributorsObject.distId
            }
            const {data} = await addBlackHttp({params:{pages:1,pageSize:10,distId:distId,keyWord:this.inputStr}})
            if(data && data.code == 0 && data.data.list.length==0) {
              noticeMsg = '该用户未被拉入黑名单，如有后续违规请联系客服，避免线下交易！'
            } else {
              noticeMsg = '该用户已被拉入黑名单，请终止交易！'
            }
            this.$message({
              message: noticeMsg,
              offset: 530,
              duration: 2000,
              customClass: 'myMessageCss'
            })
            this.inputStr = ''
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    // 微信验证另外处理
    async goToCheckWx() {
      let noticeMsg = ''
      if(this.inputWxStr.length < 6) {
        this.$message({
          message: '请输入正确的客服微信号！',
          offset: 530,
          duration: 2000,
          customClass: 'myMessageCss'
        })
      } else {
        try {
          const {data} = await queryVerifyCustomerHttp({params:{queryType:2,queryValue:this.inputWxStr}})
          if(data && data.code == 0 && Object.keys(data.data).length>0) {
            noticeMsg = `经验证：${this.inputWxStr}为真客服！`
          } else {
            noticeMsg = `经验证：${this.inputWxStr}为假客服！`
          }
          this.$message({
            message: noticeMsg,
            offset: 530,
            duration: 2000,
            customClass: 'myMessageCss'
          })
          this.inputWxStr = ''
        } catch (error) {
          console.log(error);
        }
      }
    }
    closeDialog() {
      this.isShowMask = false
    }
    showDialog(num:any) {
      if(num == 0) {
        this.isCheckCurstomer = false
      } else if(num == 1) {
        this.isCheckCurstomer = true
      }
      this.isShowMask = true
    }
    // 打开售后客服
    openAfterSallCus() {
      try {
        if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.adverPosMap && this.$store.state.web.distributorsObject.adverPosMap['5']) {
          let tempArr = this.$store.state.web.distributorsObject.adverPosMap['5'].filter((v:any) => v.device == 0)
          if(tempArr.length > 0) {
            if(String(tempArr[0].htUrl).includes('http')) {
              window.open(tempArr[0].htUrl, '_blank')
            } else {
              window.open(`http://wpa.qq.com/msgrd?v=3&uin=${tempArr[0].htUrl}&site=qq&menu=yes`, '_blank')
            }
          }

        } else if(this.$store.state.web.distributorsObject && this.$store.state.web.distributorsObject.customerBlock.serviceNum) {
        window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.$store.state.web.distributorsObject.customerBlock.serviceNum}&site=qq&menu=yes`, '_blank')
        }
      } catch (error) {
        console.log(error);
      }
    }
    goToDownloadApp() {
      // this.openUrl('/specilPage/downloadApp', '_blank')
      window.open('/specilPage/downloadApp', '_blank')
    }
    //打开聊天记录
    showedConBox(){
      if(this.userId){
        // this.showPopBox = true;
        //直接打开聊天页面
        this.getChatList()
        //直接打开聊天页面
      }else{
        this.$router.push('/login')
      }
    }
    getChatList() {
      let param = {
        userId: this.userId,
        page: 1,
        pagesize: 1000
      }
      getUserKefuList(param).then((res: any) => {
        let serviceList = res.result;
        // this.serviceList = res.result;
        console.log(serviceList,"serviceList")
        serviceList.forEach((item:any,index:number)=>{
          // console.log(item.visitor,"个人")
          if(item.visitor.id!==0){
            item.name = item.visitor.group_show_name ? item.visitor.group_show_name:item.visitor.department_name?item.visitor.department_name : '暂无消息'
            item.hidden = item.visitor.user_is_read==1 && item.visitor.mes_type==2;
            item.last_message = item.visitor.last_message;
            item.last_message_time = item.visitor.last_message_time;
          }
          if(item.room.id!==0){
            item.name = item.room.name;
            if(item.room.buy_id==this.userId){
              item.hidden = item.room.buy_is_read!=2;
              item.last_message = item.room.buy_message_content;
              item.last_message_time = item.room.buy_message_time;
            }
            if(item.room.seller_id==this.userId){
              item.hidden = item.room.seller_is_read!=2;
              item.last_message = item.room.seller_message_content;
              item.last_message_time = item.room.seller_message_time;
            }
          }
        })
        this.openKefuDetail(serviceList[0]) //默认点击第一个聊天
      })
    }
    openKefuDetail(item: any) {
      // item.hidden = false;
      // window.open("http://localhost:8008/chatIndex?customer_id=" + item.kefu.id + "&visitor_id=" + this.userId + "&visitor_name=" + this.phoneNum, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes")
      // window.open("http://169.254.221.13:8008/chatIndex?customer_id=" + item.kefu.id + "&visitor_id=" + this.userId + "&visitor_name=" + this.phoneNum, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes")
      if(item.room.id!==0){
        this.openToService(item.room.homeowner_id,null,item.room.id)
      }else{
        this.openToService(item.kefu.id,null,item.visitor.id)
      }
    }

    getKefuList(){
      let param = {
        userId: this.userId,
        page: 1,
        pagesize: 1000
      }
      getUserKefuList(param).then((res:any) => {
  

          res.result.some((v:any)=>{

            if(v.visitor.id!==0 && v.visitor.mes_type=== 2 && v.visitor.user_is_read=== 1){
               this.tip = true;
               this.timer = null;
              console.log(this.tip,"tip单聊")
                clearInterval(this.timer)
                return true
              
            }
            


          

            if(v.room.id != 0){
              if (v.room.buy_id == this.userId ) {
                this.tip = v.room.buy_is_read!=2;
                console.log(this.tip,"tip群聊1")
                this.timer = null;
                 clearInterval(this.timer)
                return true
                // console.log( this.tip = v.room.buy_is_read!==2,"是否阅读")
                // debugger
              }
              
              if (v.room.seller_id == this.userId) {
                this.tip = v.room.seller_is_read !==2;
                if(this.tip){
                  this.tip = true
                  console.log("卖家未读",this.tip,v.room.seller_is_read)
                  console.log(this.tip,"tip群聊2")
                  this.timer = null;
                  clearInterval(this.timer)
               
                  return true
                }
               
               
              }
            }
          })

          console.log(this.tip,"最后")
      }
      
    );
    }
    //关闭聊天窗口
  @Emit()
  closePopBox1(needReload: boolean){
    this.showPopBox = needReload;
  }
}
