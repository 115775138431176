import { restful } from '../utils/restful'

//下单
export function placeOrderHttp(par = {}) {
  var url = '/api/order/placeOrder'
  return restful(par, url,true)
}

//订单详情接口
export function orderDetailHttp(par = {}) {
  var url = '/api/order/orderDetail'
  return restful(par, url,true)
}

//客服交易-线下交易接口
export function offlineTranSubHttp(par = {}) {
  var url = '/api/order/offlineTranSub'
  return restful(par, url,true)
}

//订单接口
export function queryOrderListHttp(par = {}) {
  var url = '/api/order/queryOrderList'
  return restful(par, url,true)
}

//订单评价
export function orderEvalHttp(par = {}) {
  var url = '/api/order/orderEval'
  return restful(par, url,true)
}

//订单确认收货
export function confirmRecGoodsHttp(par = {}) {
  var url = '/api/order/confirmRecGoods'
  return restful(par, url,true)
}

//用户删除订单
export function deleteOrderHttp(par = {}) {
  var url = '/api/order/deleteOrder'
  return restful(par, url,true)
}

//每日成交订单【根据日期的一个列表】
export function dailyOrderHttp(par = {}) {
  var url = '/api/order/everydayDealOrderList'
  return restful(par, url,false)
}

//成交订单总数
export function distOrderCountHttp(par = {}) {
  var url = '/api/order/distCount'
  return restful(par, url,false)
}

//下单时获取上次的缓存联系信息
export function transactionCacheContactInfoHttp(par = {}) {
  var url = '/api/order/transactionCacheContactInfo'
  return restful(par, url, true)
}

//
export function creatOrderBuyInfo(par = {}) {
  var url = '/api/order/createBuyInfo'
  return restful(par, url, true)
}

// 发送短信验证码
export function creatOrderSellInfo(par = {}) {
  var url = '/api/order/createSellerInfo'
  return restful(par, url, true)
}

//提升包赔等级
export function getcompensation(par = {}){
  var url = '/api/order/updataOrderInsure'
  return  restful(par, url,true)
}

//获取浏览记录
export function getHistory(par = {}){
  var url = '/api/goods/footMarkList'
  return  restful(par, url,true)
}

//添加足迹
export function addHistTory(par = {}){
  var url = '/api/goods/createFootMarkList'
  return  restful(par, url,true)
}

//查看砍价订单
export function getBargainOrder(par = {}){
  var url = '/api/user/getUserBargainGoodsList'
  return  restful(par, url,true)
}

