import { restful } from '../utils/restful'

//客服验证  【queryType】1 qq 2 微信 3 电话
export function queryVerifyCustomerHttp(par = {}) {
  var url = '/api/cusSer/queryVerifyCustomer'
  return restful(par, url ,false)
}

//获取售前客服列表
export function queryPreSaleSerList(par = {}) {
  var url = '/api/dist/serQqList'
  return restful(par, url ,false)
}



