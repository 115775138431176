import { restful } from '../utils/restful'

//微信公众号得登陆方式
export function wechatLoginHttp(par={}){
  var  url= `/api/operation/wechat_login`
  return restful(par,url)
}

//根据分站拉取微信公众号的二维码
export function publicWechatQRCodeHttp(par={}){
  var  url= `/api/operation/PublicWechatQRCode`
  return restful(par,url)
}

