
  import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
  import {State, Getter, Action, Mutation, namespace} from 'vuex-class'
  import {wechatLoginHttp} from "~/api/operation";
  import {getUserInfoHttp} from "~/api/user";
  @Component({
    name:"djhNav2",
    components: {
      wechatLogin: require('~/components/comm/djhModal/wechatLogin.vue').default,
    },
  })
  export default class djhNav2 extends Vue {
    public setCookie:any //设置cookie
    public getCookie:any //获取cookie
    public errorToastFn:any
    public eventMsgTost:any
    public eventNotifyTost:any //接口异常抛出
    private isLogined : Boolean = false // 是否已登录
    private nickName : string = '' // 是否已登录
    private wechatShow:boolean = false
    private loading:boolean = true
    private wechatValue:any = { url:'', code:''}
    private timer:any
    mounted(){
      this.nickName=this.getCookie('nickName')
      // console.log(this.getCookie('nickName'),'=======this.getCookie()========')
      setTimeout(()=>{
        console.log(this.$store.state.web.distributorsObject,'=======distributorsObject========')
      },50)
      if (this.getCookie()) {
        this.isLogined=true
      }
    }
    //关闭定时任务
    @Emit()
    closeLoginModal(msg: boolean){
      this.wechatShow=msg
      clearInterval(this.timer)
    }
    @Emit()
    // 第三方登录
    async thirdLogin(type:string){
      this.wechatShow=false
      if (type=='qq') {

      } else {
        try {
          this.wechatShow=true
          let {data} = await wechatLoginHttp({method:'POST'})
          setTimeout(()=>{
            this.loading=false
          },400)
          this.wechatValue=data
          try {
            this.timer = setInterval(async () => {
              let { data } = await wechatLoginHttp({ method: 'GET', id:this.wechatValue.code+'u' })
              console.log(data,'==========data===========')
              if (data && data.token) {
                clearInterval(this.timer)
                this.setCookie('token', data.token)
                try {
                  let userData=await getUserInfoHttp()
                  if (this.errorToastFn(userData.data)) {
                    this.setCookie("nickName", userData.data.data.nickName)
                    this.setCookie("userId", userData.data.data.userId)
                    this.setCookie("actor", userData.data.data.actor)
                    this.setCookie("phoneNum", userData.data.data.phoneNum)
                  }
                  window.open('/', '_self')
                } catch (e) {
                  this.eventNotifyTost('提示', '网络超时', 'warning')
                }
              }
            }, 1500)
          }catch (error) {
            this.errorToastFn(error)
          }
        }catch (error) {
          this.errorToastFn(error)
        }
      }

    }
  }
