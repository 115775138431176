import Cookie from "../utils/cookie/cookie";
import { deviceType } from "../utils/deviceType.js";
import { getDistributorHttp } from "../api/distributor";
export default async function ({ store, route, req, res, redirect, content, from, error, params, query }) {

  if(process.browser) {
    console.log(route, route.path,'nowRoute');
    const nowRouteWare = route.fullPath.split('/')
    
    // console.log(nowRouteWare[1].split('=')[1].substring(0,nowRouteWare[1].split('=')[1].indexOf('#')),'=====nowRoute====22222======')
    /*  if (nowRouteWare[1].includes('?recommend_code=')){
        redirect(`http://169.254.221.136:8080/#/pages/home/home/defaultHome/?recommend_code=`+nowRouteWare[1].split('=')[1].substring(0,4))
      }*/
  }
  if (process.server) {
    
    // console.log('11111111')
    //如果当前运行的是分站目录则执行以下操作
    // 判断是否是手机端打开路由，是则跳至手机端
    const nowRoute = route.path.split('/')
    const nowRouteWare = route.fullPath.split('/')
    console.log('nowRouteWare',nowRouteWare);
    var host = req.headers.host
    if (deviceType(req.headers["user-agent"]).type !== "pc") {
      if (nowRoute[1] == 'detail' && !!nowRoute[2]) { //判断是否需要进行特殊路由跳转（电脑端商品详情跳手机端商品详情）
        if (host.includes("hk1686")) {
          redirect(`https://www.hk1686.com/x#/pages/buy/productDetail/productDetail?goodsSn=${nowRoute[2]}`)
        }else {
          redirect({path: `/h/goodsDetail/${nowRoute[2]}`})
        }
      }else if (nowRoute[1] == 'JDetail' && !!nowRoute[2]) { //判断是否需要进行特殊路由跳转（电脑端商品详情跳手机端商品详情）
        redirect({ path: `/h/goodsDetail/${nowRoute[2]}` })
      } else if (nowRoute[1] == 'news' && !!nowRoute[2]) { //公告
        if (host.includes("hk1686")) {
          redirect(`https://www.hk1686.com/x#/pages/home/bulletinDetail/bulletinDetail?newsId=${nowRoute[2]}`)
        } else {
          redirect({path: `/h/goodsDetail/${nowRoute[2]}`})
        }
      }else if (nowRouteWare[1].includes('wares')&&nowRouteWare[2].includes('?')) { //商品列表页
        if (host.includes("hk1686")) {
          let montage = nowRouteWare[2].split('?')[1]
          if (!nowRouteWare[2].includes('drainage')) {
            const charArr = nowRouteWare[2].split('?')[1].split('&')
            let index =  charArr.findIndex((item)=>item.includes('gameId'))
            let index2 =  charArr.findIndex((item)=>item.includes('gid'))
            if(index !== -1 ){
              redirect(`https://www.hk1686.com/x#/pages/buy/productList/productList?id=${charArr[index].split('=')[1]}&${charArr[index]}&${montage}`)
            }
            if(index2 !== -1 ){
              redirect(`https://www.hk1686.com/x#/pages/buy/productList/productList?id=${charArr[index2].split('=')[1]}&${charArr[index]}&${montage}`)
            }

          
          } else {
            // /wares/?gid=86&gt=1&drainage=sss
            const charArr = nowRouteWare[2].split('?')[1].split('&')
            let index =  charArr.findIndex((item)=>item.includes('gameId'))
            let index2 =  charArr.findIndex((item)=>item.includes('gid'))
            if(index !== -1 ){
              redirect(`https://www.hk1686.com/x#/pages/buy/productList/productList?id=${charArr[index].split('=')[1]}&${charArr[index]}&${charArr[2]}&${montage}`)
            }
            if(index2 !== -1 ){
              redirect(`https://www.hk1686.com/x#/pages/buy/productList/productList?id=${charArr[index2].split('=')[1]}&${charArr[index]}&${charArr[2]}&${montage}`)
            }
            
          }
        }
      }else if (nowRouteWare[1].includes('contact')){
      
      
        if(nowRouteWare[1].includes('drainage')){
         
          redirect(`https://www.hk1686.com/x#/pages/contact/contact?${nowRouteWare[1].split('?')[1]}`)
        }else{
          redirect(`https://www.hk1686.com/x#/pages/contact/contact`)
        }
      
      }else if (nowRouteWare[1].includes('recommend_code')){
        redirect(`https://www.hk1686.com/#/pages/home/home/defaultHome/?recommend_code=${nowRouteWare[1].split('=')[1]}`)
      }else if (nowRouteWare[1].includes('code')){
        redirect(`https://www.hk1686.com/#/pages/home/home/defaultHome/?${nowRouteWare[1].split('?')[1]}`)
      }else if (nowRouteWare[1].includes('kefuSystem')){
        redirect(`https://www.hk1686.com/#/pages/chat/history${nowRouteWare[1]}`)
        
      }else {
        redirect({ path: '/x' })
      }
    }

    console.log(host, '=====host====哈哈哈哈哈=====host======')
    let aa = {
      "id": 1,
      "distId": 1,
      "distBaseConf": {},
      "customerBlock": {},
      "bottomVerInfo": {},
      "transferAccountBlock": {},
      "pictArea": {},
      "coAnchorBlocks": [],
      "adverPositions": [],
      "notifBits": [],
      "freeValTitles": [],
      "treeMenuButMap": [],
      "botQuals": [],
      "adverPosMap": {}
    }
    try {
      //1.拉取当前域名对应分站信息
      let { data } = await getDistributorHttp({ req })
      console.log(data, 'dst======拉取当前域名对应分站信息拉取当前域名对应分站信息拉取当前域名对应分站信息拉取当前域名对应分站信息===============')
      //2.将分站信息存入，vueX
      if (data.code === 0) {
        console.log('分销商接口')
        store.state.web.distributorsObject = data.data
      } else {
        store.state.web.distributorsObject = aa
        console.log('分销商接口出错1')
      }
    } catch (e) {
      console.log('分销商接口出错2')
      store.state.web.distributorsObject = aa
      // store.commit("comm/SAVEFENZHAN", data)
    }
    console.log(store.state.web.topMenuArray, '=====topMenuArray========')
  }else{
    
    // const nowRoute = route.path.split('/')
    // const nowRouteWare = route.fullPath.split('/')
    // console.log('nowRouteWare',route,nowRoute,nowRouteWare[1]);

    // debugger
    // if (nowRouteWare[1].includes('kefuSystem')){
    //   redirect(`http://localhost:8080/#/pages/chat/history${nowRouteWare[1]}`)
    // } 
 
   
 
  //  debugger
    // const nowRoute = route.path.split('/')
    // const nowRouteWare = route.fullPath.split('/')
 
    // if (nowRouteWare[1].includes('wares')&&nowRouteWare[2].includes('?')) { //商品列表页
    //   let montage = nowRouteWare[2].split('?')[1]
    //   if (!nowRouteWare[2].includes('drainage')) {
    //     const charArr = nowRouteWare[2].split('?')[1].split('&')
    //     let index =  charArr.findIndex((item)=>item.includes('gameId'))
    //     let index2 =  charArr.findIndex((item)=>item.includes('gid'))
    //     if(index !== -1 ){
    //       redirect(`http://localhost:8080/x#/pages/buy/productList/productList?id=${charArr[index].split('=')[1]}&${charArr[index]}&${montage}`)
    //     }
    //     if(index2 !== -1 ){
    //       redirect(`http://localhost:8080/x#/pages/buy/productList/productList?id=${charArr[index2].split('=')[1]}&${charArr[index]}&${montage}`)
    //     }

      
    //   } else {
    //     // /wares/?gid=86&gt=1&drainage=sss
    //     const charArr = nowRouteWare[2].split('?')[1].split('&')
    //     let index =  charArr.findIndex((item)=>item.includes('gameId'))
    //     let index2 =  charArr.findIndex((item)=>item.includes('gid'))
    //     if(index !== -1 ){
    //       redirect(`http://localhost:8080/x#/pages/buy/productList/productList?id=${charArr[index].split('=')[1]}&${charArr[index]}&${charArr[2]}&${montage}`)
    //     }
    //     if(index2 !== -1 ){
    //       redirect(`http://localhost:8080/x#/pages/buy/productList/productList?id=${charArr[index2].split('=')[1]}&${charArr[index]}&${charArr[2]}&${montage}`)
    //     }
        
    //   }
    // }
  }
  if (process.client) {
    // console.log(window.sessionStorage.getItem('pullOrNot'), 'client===靠靠靠靠靠靠靠靠靠靠靠靠靠')
    // window.aaa='aaa'
  }
}



